<template>
  <div class="form-control border-0" :style="options">
    {{ _obj.account }} <span id="dot" />
  </div>
</template>

<script>
import { InputMixin } from "../../mixins";
export default {
  name: "endpointStatus",
  mixins: [InputMixin],
  computed: {
    status() {
      let ret = "danger";
      if (this._obj.state) {
        ret = "success";
      }
      return ret;
    },
    options() {
      return {
        "--dot-color": `var(--${this.status})`,
      };
    },
  },
};
</script>

<style>
#dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--dot-color);
  display: inline-block;
}
</style>