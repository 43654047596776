import { id_field_desc } from "@/functions.js";

const descr = {
  verbose: "Новости",
  obj_type: "news",
  obj_name: "header",
  obj_key: "id",
  fields: {
    id: id_field_desc,
    header: "Заголовок",
    title: "описание",
    link: "ссылка",
    picture: "картинка",
    body: "Тело",
    date: "Дата",
  },
};

// const complex_news = `
// Виртуальная АТС от «ОБИТ» - продукт, разработанный в сотрудничестве с нашими клиентами. Ориентируясь на задачи своих абонентов, мы формируем и совершенствуем нашу услугу как ответ на самые актуальные  потребности бизнеса и вызовы рынка. В итоге мы создали инструмент, который позволяет увеличивать продажи, улучшать качество сервиса и развивать свой бизнес в любом удобном и доступном на текущий момент формате - удаленном, гибридном или офисном.

// <p class="subtext">Оператор-интегратор «ОБИТ» объявляет о запуске виртуальной телефонии на мультифункциональной платформе.</p>

// <p>Для подключения к ВАТС вам не придется тратиться на дорогостоящее дополнительное оборудование, его монтаж и настройку. Достаточно оставить заявку, оформить договор и вместе с доступом к Личному кабинету получить целый комплекс преимуществ, который открывает виртуальная телефония от “ОБИТ”:</p>

// <p>·  оперативность и гибкость системы: вы сами можете подключать и отключать сервисы, устанавливать роли и права доступа пользователей Личного кабинета, создавать и удалять внутренние номера – без заявок и сложного документооборота;</p>

// <p>·   широкие возможности для работы с клиентской базой: настройка переадресации для точечной и оперативной обработки звонков, голосовое меню для улучшения клиентского опыта, прослушивание записей разговоров для разрешения спорных ситуаций;</p>

// <p>·    современный инструментарий повышения продуктивности персонала: анализ эффективности менеджеров на основе статистики звонков, разработка программ обучения по следам самых частых ошибок, планирование и отслеживание выполнения бизнес-задач благодаря интеграции с CRM-системами.</p>

// <p>·    прозрачная финансовая отчетность, возможность самостоятельно следить за расходами на связь, формировать и скачивать бухгалтерские документы, запрашивать акты взаиморасчетов;</p>

// <p>·    самостоятельное управление телефонией 24/7 в Личном кабинете – с любого устройства, в любом месте;</p>

// <p>·    безопасность бизнеса и защита персональных данных: благодаря виртуальному номеру телефонные мошенники никогда не смогут получить доступ к мобильным номерам ваших сотрудников, а недобросовестные сотрудники, в свою очередь, будут лишены возможности установить контакт с вашими клиентами или партнерами в обход компании;</p>

// <p>·    надежность и качество связи благодаря высокой отказоустойчивости и масштабируемости системы, построенной на базе контейнеров Kubernetes.</p>

// <p>Мы понимаем, что дополнительные функции и возможности не должны влиять на главную задачу телефонии – обеспечивать качественную связь. Благодаря ключевым особенностям: собственному ПО, собственной инфраструктуре и собственному ЦОДу <p>– мы гарантируем стабильно высокое качество зоновой, междугородной и международной связи, вне зависимости от наличия и скорости Интернет-соединения, а также местоположения вашего офиса, сотрудников и партнеров.</p></p>

//     <style>
//     .subtext {
//         margin: 30px 0;
//         padding-left: 30px;
//         font-weight: 700;
//         position: relative;
//     }
//     .subtext::after{
//             content: "";
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 2px;
//             height: 100%;
//             background: #3e3e3e;
//     }
//     </style>
// `

// const complex_newsTwo = `
// <p>Масштабный переход на удаленный формат поставил перед бизнесом новые вызовы. Мы знаем, что теперь вам нужен постоянный и непрерывный контроль над тем, что происходит в вашей компании – где бы это не происходило: дома у сотрудников, в облаке или на другом континенте. Идеальный сервис сегодня – это сервис, которым можно управлять в любое время суток, из любой локации, с мгновенной скоростью исполнения.</p>
// <p>Именно это предлагает нашим абонентам Личный кабинет ОБИТ. Теперь вы самостоятельно можете подключать и отключать сервисы, выгружать финансовые и отчетные документы, контролировать свои расходы на телефонию, формировать аналитику звонков и корректировать работу своих сотрудников.</p>
// <p>Мы постарались сделать интерфейс нашего Личного кабинета максимально простым и удобным для использования - настройка не потребует ни специальных знаний, ни много времени. Однако, если Вы все-таки предпочитаете получить “продукт под ключ”, уже настроенный под ваши задачи и требования, наши специалисты сделают это для Вас в рамках услуги “ИТ-аутсорсинг”. Просто нажмите на кнопку “Обратная связь” в Личном кабинете - и Ваш персональный менеджер свяжется с Вами для уточнения деталей и оперативного решения любых других вопросов.</p>

// <p>Возможности ЛК</p>
// <p>·         Авторизация пользователя и выбор договора</p>

// <p>Для авторизации в ЛК и получения доступа к своему аккаунту перейдите по ссылке в письме, которое мы направим Вам после подписания договора. Возможности ЛК позволяют переключаться между несколькими договорами, если в периметре вашего бизнеса или профессиональной деятельности несколько юридических лиц.</p>

// <p>·         Управление ролями пользователей</p>

// <p>Открывайте сотрудникам доступ только к необходимым для работы  данным, не беспокоясь о конфиденциальности и безопасности информации: просто распределите права на основании одной из трех ролей - администратор, бухгалтер, оператор.</p>

// <p>·         Формирование и скачивание финансовых документов: актов выполненных работ, актов сверки и т.п.</p>

// <p>Экономьте время на контроль расходов и подготовку отчетности: в разделах “Финансы” и “Договор” вы и ваш бухгалтер можете сформировать и скачать финансовые документы, просматривать в режиме онлайн детализацию звонков и сервисов, проверять состояние счета на текущую дату - без бюрократии, ожидания и сложного документооборота.</p>

// <p>·         Управление количеством линий и сотрудников</p>

// <p>Масштабируйте свой бизнес и синхронизируйте его с вашей телефонией: самостоятельно подключайте и отключайте дополнительные линии и номера, добавляйте и удаляйте дополнительные устройства, подключайте сотрудников и пользователей с доступом в ЛК.  </p>

// <p>·         Подключение дополнительных номеров</p>

// <p>Повышайте лояльность своих клиентов и гарантируйте себе дополнительное преимущество перед конкурентами с помощью “красивого” или бесплатного номера 8 800. А еще вы всегда можете подключить к ВАТС “ОБИТ” номер стороннего оператора, чтобы не терять связь со своими клиентами и партнерами. Подключение займет не более 24 часов.</p>

// <p>·       Маршрутизация вызовов</p>

// <p>Обрабатывайте весь поток входящих звонков и оказывайте качественную поддержку своим клиентам, настроив переадресацию вызовов в зависимости от графика работы Вашей компании, а также запроса звонящего. Для этого необязательно привлекать вашего ИТ-администратора - простой интерфейс позволит в буквальном смысле выстроить схему маршрутизации для вашей телефонии прямо с мобильного устройства. </p>

// <p>·         Настройка голосового меню IVR</p>

// <p>Начинайте работу с клиентом еще до того, как оператор поднимет трубку. Загрузите музыкальную композицию или голосовое приветствие, чтобы сформировать у звонящего необходимое вам представление о компании или рассказать об акциях и услугах. Удалить или поменять файл можно за несколько секунд, а впечатление о компании останется надолго.</p>

// <p>·         Выбор и настройка алгоритма распределения звонков</p>

// <p>Управляйте нагрузкой персонала и повышайте продуктивность работы колл-центра, службы поддержки или отдела продаж с помощью одной из схем распределения входящих вызовов: звонить всем сразу или по очереди, начиная с самого опытного оператора.</p>

// <p>·         Заявка на интеграцию с CRM</p>

// <p>Получайте максимум от ваших телефонных звонков с помощью интеграции с CRM-системой. Оформите заявку на интеграцию в разделе “Финансы” Личного кабинета и начинайте монетизировать коммуникации вашей компании. </p>

// <p>·         Доступ к записям разговоров</p>

// <p>Подключите сервис хранения записей разговоров и контролируйте качество внешних и внутренних звонков в вашей компании. Записи всех отвеченных вызовов в Телефонном кабинете сопровождаются информацией о дате и времени звонка, номере, с которого он поступил, длительности разговора и сотруднике, который его вел. </p>

// <p>·         Статистика и аналитика</p>

// <p>Просматривайте и сохраняйте в удобном для вас формате историю и статистику вызовов. Вы можете сформировать данные за день, неделю или месяц с выборкой по входящим и исходящим вызовам, внешним и внутренним линиям.</p>

// <p>·         Обратная связь</p>

// <p>Связывайтесь с вашим персональным менеджером в режиме 24/7, консультируйтесь по услуге и сервисам, уточняйте детали. Мы всегда на связи!</p>

// <style>
// .subtext {
//     margin: 30px 0;
//     padding-left: 30px;
//     font-weight: 700;
//     position: relative;
// }
// .subtext::after{
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 2px;
//         height: 100%;
//         background: #3e3e3e;
// }
// </style>
// `

const complex_newsThree = `
<p>
Тренд на удаленную работу, распределенные команды и гибкий график прочно закрепился в новой бизнес-реальности и стал оптимальным вариантом функционирования для многих компаний. При этом определенные ограничения, связанные с таким форматом, легко преодолеть, используя современные технологии. Виртуальная АТС от «ОБИТ» позволяет закрыть сразу несколько вопросов, связанных с организацией удаленной работы.</p>
<p>Первый и ключевой – внутренние и внешние коммуникации компании. Вне зависимости от того, где находятся операторы вашего колл-центра, службы поддержки, горячей линии или отдела продаж, с телефонией «ОБИТ» вы не пропустите ни одного звонка от клиента, партнера или контрагента. Кроме того, вне зависимости от того, где находитесь вы, благодаря Личному кабинету вы сможете контролировать и корректировать работу своих сотрудников – достаточно устройства с подключением к сети Интернет.</p>

<p class="subtitle">
Как это работает?
</p>
<p class="subtext">

1.       Входящие звонки поступают на платформу виртуальной АТС, которая распределяет вызовы по заданной вами схеме.<br>
2.       Абонент слышит голосовое приветствие и посредством голосового меню соединяется с  ответственным менеджером или оператором.<br>
3.       При отсутствии голосового меню вызов направляется на любое доступное устройство сотрудника в настроенной вами схеме.

</p>
<p class="subtitle">
Возможности ВАТС от ОБИТ для удаленной работы:
</p>

<li class="list-title">
Сохранение номера
</li>

Ваши клиенты не потеряют вас – подключите ваши номера к ВАТС «ОБИТ» и принимайте вызовы на те устройства, которыми оборудованы ваши удаленные рабочие места: стационарные аппараты, мобильные телефоны, софтфоны.


<li class="list-title">Простая настройка</li>
Организация удаленной службы поддержки или колл-центра не потребует приобретения дорогостоящего оборудования, установки сложного ПО и обязательного выезда специалиста по домам сотрудников. Для начала работы с виртуальной АТС потребуется только гарнитура, ПК или ноутбук с выходом в Интернет и пара кликов в Личном кабинете.

<li class="list-title">Масштабирование бизнеса</li>

Виртуальная телефония позволяет значительно расширить географический охват бизнеса, объединив сотрудников в разных городах и даже странах в одну сеть. Контролировать и анализировать работу каждого вы сможете благодаря функции записи и архивирования звонков на необходимый срок.



<li class="list-title">Интеграция с CRM</li>

Вы сможете управлять бизнес-процессами внутри своей компании не менее эффективно, чем в офисе, благодаря интеграции ВАТС с CRM. Автоматизируйте обработку звонков и заключение сделок внутри распределенных команд или всей сети. Телефония «ОБИТ» интегрируется с любой из популярных систем автоматизации бизнеса. 


<li class="list-title">Голосовое приветствие и переадресация звонков без секретаря</li>


Даже если ваш офис закрыт для посетителей, ваши клиенты не останутся без внимания – благодаря голосовому меню вы можете записать и загрузить через Личный кабинет любое приветствие от имени компании, а также актуальное информационное сообщение: рекламу, оповещение о графике работы, объявление об акции. Распределение звонков между сотрудниками по выбранному Вами сценарию поможет качественно и оперативно обработать каждый входящий вызов.

Помимо ВАТС, перейти максимально быстро и эффективно на удаленный формат без угрозы для непрерывности бизнеса, вам помогут другие сервисы от «ОБИТ»: 1С в Облаке, виртуальные серверы, ИТ-аутсорсинг. 






<style>
.subtitle{
    margin: 0;
    font-weight: bold;
}
ul{
    padding: 0;
}
.list-title{
    margin-left: 40px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.subtext {
    margin: 30px 0;
    padding-left: 30px;
    font-weight: 700;
    position: relative;
}
.subtext::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #3e3e3e;
}
</style>


`;

const complex_newsFour = `
<p>
Главная функция имиджевого или «красивого» номера – остаться в памяти ваших действующих или потенциальных клиентов. По статистике количество входящих звонков на имиджевые номера почти в 6 раз выше, чем на обычный, а легко запоминающийся телефон на баннере или биллборде повышает эффективность рекламы. Удобная комбинация цифр телефонного номера может стать сильным конкурентным преимуществом, если телефон – основной канал приема заказов или первичных обращений.</p>
<p>Службы такси и доставки, медицинские центры и клиники, Интернет-магазины, справочные службы, колл-центры b2c - сферы бизнеса, где имиджевый номер стал обязательным условием присутствия на рынке.</p>
<p>На Ваш выбор – один из 18 000 «красивых» комбинаций в номерной емкости «ОБИТ» в кодах Петербурга, Москвы или Казани. Например, 67-777-67; 212-0000; 386-86-86; 207-1000; 612-2000; 7777-33-7; 984-9000 и т.д. </p>


<style>
.subtitle{
    margin: 0;
    font-weight: bold;
}
ul{
    padding: 0;
}
.list-title{
    margin-left: 40px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.subtext {
    margin: 30px 0;
    padding-left: 30px;
    font-weight: 700;
    position: relative;
}
.subtext::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #3e3e3e;
}
</style>


`;

const complex_newsFive = `
<p>Используя номер с кодом набора «8 800», клиенты и партнеры смогут бесплатно позвонить вам из любого региона России со стационарного или мобильного телефона. Это значительно повышает вероятность обращения потенциального клиента именно в вашу компанию, а также лояльность к бренду еще до фактического опыта взаимодействия с ним. Кроме того, бесплатный для клиента звонок дает возможность вашему менеджеру в полном объеме озвучить свое предложение и представить в максимально выгодном свете ваши услуги или товар.</p>
<p>«Бесплатный вызов по 8-800» незаменим, если вы запускаете горячие линии, проводите маркетинговые акции, организуете информационно-справочные центры. Кроме того, это бюджетный канал связи с собственными сотрудниками в удаленных филиалах.</p>
<p>Оплачивая звонки по номеру  8 800, вы гарантированно инвестируете в будущие сделки.</p>



<style>
.subtitle{
    margin: 0;
    font-weight: bold;
}
ul{
    padding: 0;
}
.list-title{
    margin-left: 40px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.subtext {
    margin: 30px 0;
    padding-left: 30px;
    font-weight: 700;
    position: relative;
}
.subtext::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #3e3e3e;
}
</style>


`;
const complex_news6 = `<div class="newslist__text">
<p>
Делимся с вами обновлениями в Личном кабинете ВАТС:
</p>

<p>

1.       <strong>Добавили кнопку для выгрузки отчетов по входящим и исходящим вызовам.</strong> <br>
Теперь вы можете одним кликом формировать отчеты в удобном формате (.xls или .csv) и загружать их в любые системы для анализа.
<p>

2.      <strong> Подключили функцию управления временем вызова устройства. </strong> <br>
То есть, если к одному внутреннему номеру подключен и SIP- и мобильный телефон, вы можете задавать время вызова не только для номера, но и для каждого из устройств. Аналогично - по каждому из устройств - можно задать стратегию обработки вызовов: одновременно или последовательно. <p>

3.       <strong> Усилили защиту ваших данных. </strong> <br>
Теперь для SIP-устройства можно установить фильтр IP-адресов, разрешенных для регистрации. <p>

4.  <strong> Добавили подраздел “Физические линии” в раздел “Настройки”. </strong> <br>
Вы можете подключить или переподключить линию связи к одному из внутренних номеров прямо в личном кабинете - например, если ваши сотрудники сменят рабочее место в рамках одного офиса. А еще вы сможете без обращения в техподдержку устанавливать переадресацию вызовов на физической линии (безусловную или условную - по занятости или неответу). <p>

5. <strong> Запустили веб-страницы под телеком- и ИТ-услуги ОБИТ с возможностью подключения из ЛК.  </strong> <br>
Достаточно нажать на кнопку “Подключить” и запрос отправится непосредственно вашему менеджеру. <p>

<style>
.subtitle{
    margin: 0;
    font-weight: bold;
}
ul{
    padding: 0;
}
.list-title{
    margin-left: 40px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.subtext {
    margin: 30px 0;
    padding-left: 30px;
    font-weight: 700;
    position: relative;
}
.subtext::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #3e3e3e;
}
</style>


</div>`;

const complex_news05 = `
<p>Оперативные и удобные средства связи стали неотъемлемой частью в решении бизнес-задач. </p>
 
<p>Согласно исследованиям «ТМТ Консалтинг» в 2021 году охват рынка ВАТС в России достиг 17,7 млрд рублей, что на 30,8% больше чем в 2020-м году. </p>
 
<p>В 2021 году число компаний, использующих ВАТС выросло на 12% и насчитывает 400,8 тыс по данным того же исследования. </p>
 
<p>"На компании торгового сектора приходится около 34% рынка. До 14% в 2021 году выросла доля компаний, оказывающих различные услуги, увеличилась доля госсектора. Постепенно растет доля компаний-клиентов, подключающих к ВАТС более 50 пользователей." (ТМТ Консалтинг) </p>
 
<p>Среди наших клиентов, пользующихся услугами Виртуальной АТС есть представители различных сфер бизнеса, по запросу которых мы постоянно совершенствуем свои услуги и находим кастомизированные решения для конкретных ситуаций. </p>

<p><strong>Какие изменения произведены в Личном кабинете Виртуальной АТС: </strong></p>
 
<p>🔶 Детализация платных вызовов. Теперь легко можно фильтровать список звонков за прошлый и текущий месяц, задавать нужный диапазон дат, скачивать данные в удобном формате. Вы сможете следить за расходами на трафик, формировать отчеты за любой период. </p>
 
<p>🔶 Расширенные возможности в разделе "Звонки". Добавили кнопку "Фильтр" и отладили работу режима фильтрации. </p>
 
<p>🔶 Добавили закладку "Пропущенные". Во вкладке открывается список только пропущенных звонков. </p>
 
<p>🔶 Доработали уведомления при сохранении, отмене или перезагрузке схемы маршрутизации. Настраивать маршрутизацию теперь стало гораздо удобнее и все больше и больше напоминает игру. </p>
`;

const complex_news06 = `
<p>“Тенсар Инновэйтив Солюшнз” - крупный производитель материалов для строительства подпорных стен, стабилизации грунта и армогрунтовых решений. Входит в британский холдинг Tensar. Включает производственную площадку в Петергофе, административный офис в Санкт-Петербурге и представительство в Москве.</p>
<p><strong>Предыстория:</strong></p>
<p>“ОБИТ” работает с “Тенсар” с 2013 года. Кроме услуг связи, у клиента была установлена аналоговая телефония на 50 линий, включая 5 внешних и 45 внутренних, которые также обслуживались силами специалистов “ОБИТ”. В 2018 году были подключены сервисы уведомлений о пропущенных вызовах и автоответчик. В 2021 году “Тенсар” запустил новую стратегию развития, которая включала активный рост компании и открытие филиала в Москве. Помимо стандартного набора услуг связи, необходимого для функционирования офиса, компании потребовались инструменты и сервисы, которые позволили бы комплексно решать новые задачи.</p>
<p><strong>Задача:</strong></p>
<p>Организация виртуальной телефонии на всех площадках “Тенсар”; отслеживание эффективности рекламных кампаний по входящим и исходящим вызовам, внедрение бесплатных входящих звонков для увеличение потока обращений и рост продаж за счет привлекательного номера телефона компании.</p>
<p><strong>Что было сделано:</strong></p>
<li>выполнено подключение московского офиса к ВАТС;</li>
<li>выполнен перевод двух площадок в СПб на ВАТС;</li>
<li>предоставлены в аренду 4 городских телефонных номера по СПб и один - московский номер;</li>
<li>предоставлены номера 8800/ 8804, в т.ч. “красивый номер”;</li>
<li>подключен сервис email-уведомлений о пропущенных вызовах на номер 8800;</li>
<li>подключен сервис “Автоответчик” на номер 8800.</li>
`;

const complex_news07 = `
<p>Kingspan Group - это признанный мировой лидер в области теплоизоляции и ограждающих конструкций для промышленного строительства. Штаб-квартира российского представительства Kingspan находится в Санкт-Петербурге, также компания располагает двумя производственными площадками - в Ленинградской области и Ставропольском крае и офисом продаж в Москве.</p>
<p><strong>Предыстория:</strong></p>
<p>“ОБИТ” работает с Kingspan Group с 2011 года и предоставляет услуги связи для штаб-квартиры в Санкт-Петербурге. Телефонную связь для производственной площадки в Ставропольском крае Kingspan Group предоставлял местный оператор. Возникла необходимость в единой коммуникационной платформе, которая объединила бы все производственные территории, отдел продаж и административный блок. Возникла потребность в том, чтобы звонки с арендованных номеров в Невинномысске поступали на мобильные телефоны менеджеров в Санкт-Петербурге. Местный провайдер не смог выполнить запрос. ИТ-специалисты обратились с этой задачей в “ОБИТ”.</p>
<p><strong>Задача:</strong></p>
<p>Переход в Виртуальную АТС, подключение к ВАТС транка от локального провайдера и управление звонками.</p>
<p><strong>Что было сделано:</strong></p>
<li>выполнен перевод на ВАТС;</li>
<li>настроена сложная маршрутизация вызовов;</li>
<li>сохранен и подключен к ВАТС от ОБИТ номер компании в Ставропольском крае;</li>
<li>предоставлены в аренду 2 городских номера (по СПб и Мск);</li>
<li>реализована интеграция ВАТС с CRM.</li>
`;

const complex_news08 = `
<p>«ОБИТ» пополнил свой портфель услуг сервисом по поддержке еще одного продукта от российского вендора Naumen - платформы для создания интеллектуальных ботов Naumen Erudite.</p>

<p>Пул компетенций «ОБИТ» в области сервисного обслуживания ПО для контакт-центров расширился и теперь включает, помимо техподдержки омниканальной платформы автоматизации контактного центра Naumen Contact Center, техподдержку ИИ-платформы для создания голосовых роботов и чат-ботов Naumen Erudite.</p>

<p>Naumen, российский разработчик решений в области управления цифровой инфраструктурой, клиентскими коммуникациями и сервисом, и «ОБИТ», провайдер комплексных ИТ- и телекоммуникационных решений для бизнеса, заключили соглашение о стратегическом партнерстве в 2021 году. При этом успешное сотрудничество компаний продолжается более 6 лет. «ОБИТ» помог внедрить и взял на себя техподдержку платформы Naumen Contact Center для ряда своих клиентов, среди которых крупнейший в России DIY-ритейлер и ведущий застройщик федерального уровня.</p>

<p>Решение Naumen Erudite использует технологии искусственного интеллекта для создания диалоговых ботов и их внедрения в клиентский сервис компаний. Платформа входит в единый реестр отечественного ПО. Чат-бот и голосовые помощники на Naumen Erudite обрабатывают более 35 млн диалогов с клиентами ежегодно и помогают обеспечивать качественный клиентский сервис в таких компаниях, как банк ДОМ.РФ, ОТП Банк, аптечные сети Ригла, Живика и Будь Здоров, сервис онлайн-платежей ЮMoney, Уральский банк реконструкции и развития, Мосэнергосбыт.</p>

<p><i>«За последний год востребованность отечественных ИТ-продуктов среди наших клиентов выросла в несколько раз,</i> – говорит Михаил Телегин, руководитель коммерческого департамента «ОБИТ». <i>– Импортозамещение в сфере программных продуктов – одна из наиболее приоритетных задач российского бизнеса на сегодняшний день. И мы прикладываем максимум усилий, чтобы помочь своим клиентам решить ее – в той части, где наша ИТ-индустрия может предложить высокоэффективные надежные решения. Наш партнер Naumen – один из примеров такого вендора».</i></p>
`;

const complex_news09 = `
<p>Дорогие абоненты! В Личном кабинете теперь можно работать не только с Виртуальной АТС, но и:</p>
<li>отслеживать подключенные услуги “Интернет” и “Цифровая телефония”</li>
<li>смотреть и скачивать статистику по услугам и детализацию звонков</li>
<li>прослушивать записи звонков и по классической телефонии</li>
<li>получать счета за услуги связи и контролировать взаиморасчеты</li>
<li>заказывать в один клик акты сверки</li><br>

<p>Расширенный функционал Личного кабинета доступен в новых разделах “Интернет” и “Цифровая телефония”.</p>
`;

const complex_news10 = `
<p>ИТ-поддержка от «ОБИТ» позволит индивидуальным предпринимателям, стартапам и малым компаниям обеспечить бесперебойную работу ИТ-инфраструктуры в условиях ограниченных ресурсов. Стоимость технического обслуживания, которое будут выполнять сертифицированные ИТ-специалисты, начинается от 136 рублей.</p>

<p>Помимо решения типичных ИТ-задач в рамках деятельности штатного администратора, компании смогут получить экспертную оценку своей ИТ-инфраструктуры с рекомендациями по оптимизации затрат и повышению эффективности ее работы, а также круглосуточный мониторинг действующих систем.</p>

<p>
  В «ОБИТ» рассчитывают, что эта мера поддержки позволит компаниям из МСП легче адаптироваться к тем условиям, которые сформировались на отечественном рынке в силу геополитических факторов, и продолжить цифровое развитие своего бизнеса, несмотря на резкое ограничение ресурсов - как материальных, так и человеческих.
</p>
<p>
  «События прошлого года затронули малый бизнес не менее сильно, чем крупный. Он в той же степени столкнулся с необходимостью миграции на отечественные решения, дефицитом ИТ-кадров, отсутствием необходимого пула компетенций для поддержки работы ИТ-инфраструктуры, - говорит Михаил Телегин, заместитель генерального директора «ОБИТ» по стратегическим проектам. - Однако, как мы видим на примере наших клиентов, далеко не у всех есть достаточные ресурсы, чтобы оперативно решить эти проблемы. Мы в «ОБИТе» уверены - от «здоровья» МСП во многом зависит состояние бизнеса и рынка в целом, поэтому выступили с инициативой поддержать небольшие компании и поделиться своими компетенциями и опытом, а также человеческим ресурсом, который сейчас недоступен большинству из них».
</p>
<p>
  По данным экспертов, 63% российских компаний тратят более полугода на закрытие вакансий в сфере ИТ, при этом 75% находят специалистов в два раза быстрее с помощью повышенных зарплатных предложений. Это значительно снижает шансы малого бизнеса в борьбе за сотрудников и заставляет обращаться к услугам начинающих или неквалифицированных специалистов, которые в большинстве случаев не способны решить такие критически важные задачи, как обеспечение ИБ, импортозамещение западного ПО, сервисная поддержка инфраструктуры и т.п. Крупные игроки, как правило, не заинтересованы в таких клиентах из-за низкой маржинальности и недостаточной экономической привлекательности проектов по их поддержке. Совокупность этих факторов ставит под угрозу бесперебойность работы ИТ-систем довольно многочисленной категории предприятий, соответственно, замедляет общие темпы цифровизации отечественного бизнеса. Один из способов минимизировать этот риск - создание продуктов и сервисов, адаптированных под возможности и задачи предпринимателей в наиболее уязвимых сегментах.
</p>

`;

const complex_news11 = `
<p>
  Дорогие клиенты, наша команда подготовила персональные предложения для вашего бизнеса. Выбирайте нужную услугу и пользуйтесь бесплатными преимуществами.
</p>

<ul>
  <li>
    <b>
      Облачное видеонаблюдение
    </b>
    для круглосуточного онлайн контроля от 500 р/мес.
    <b>
      7 дней тестирования и видеокамера БЕСПЛАТНО.
    </b>
  </li>
  <li>
    <b>
      Виртуальная АТС
    </b>
    чтобы не упускать ни одного звонка от клиента.
    <b>
      7 дней теста и IP-телефон БЕСПЛАТНО.
    </b>
  </li>
  <li>
    <b>
      Виртуальный сервер
    </b>
    чтобы не покупать железный.
    <b>
      7 дней БЕСПЛАТНО.
    </b>
  </li>
</ul>


<p style="text-decoration: underline;">Успейте воспользоваться до 1 декабря!</p>

<p>
Для заказа услуги со специальным предложением, звоните по телефону: +7 (812) 622-0927 или обратитесь к своему персональному менеджеру.
</p>
`

const test_data = [
  // {
  //     id: 'asfsdasfas1',
  //     date: new Date(),
  //     header: 'Новости',
  //     title: 'Виртуальная АТС от «ОБИТ» - продукт, разработанный в сотрудничестве с нашими клиентами. Ориентируясь на задачи своих абонентов, мы формируем и совершенствуем нашу услугу как ответ на самые актуальные  потребности бизнеса и вызовы рынка. В итоге мы создали инструмент, который позволяет увеличивать продажи, улучшать качество сервиса и развивать свой бизнес в любом удобном и доступном на текущий момент формате - удаленном, гибридном или офисном.',
  //     link: 'Виртуальная АТС - когда связь делает бизнес сильнее',
  //     body: complex_news,
  //     picture: require("@/assets/news/2.jpg")
  // },
  // {
  //     id: 'asfsdasfas2',
  //     date: new Date(),
  //     // header: 'Новость 2',
  //     title: 'Масштабный переход на удаленный формат поставил перед бизнесом новые вызовы. Мы знаем, что теперь вам нужен постоянный и непрерывный контроль над тем, что происходит в вашей компании – где бы это не происходило: дома у сотрудников, в облаке или на другом континенте. Идеальный сервис сегодня – это сервис, которым можно управлять в любое время суток, из любой локации, с мгновенной скоростью исполнения.',
  //     link: 'Личный кабинет виртуальной АТС “ОБИТ” - эффективный инструмент управления бизнесом у вас под рукой',
  //     body: complex_newsTwo,
  //     picture: require("@/assets/news/3.jpg")
  // },
  {
    id: '11',
    date: new Date(2023, 11 - 1, 2),
    //title: '"ОБИТ" поддержит малый бизнес.',
    link: 'Специальные предложения от «ОБИТ»!',
    body: complex_news11,
    picture: require('@/assets/news/news_11.jpg')
  },
  {
    id: '10',
    date: new Date(2023, 8 - 1, 10),
    //title: '"ОБИТ" поддержит малый бизнес.',
    link: '"ОБИТ" поддержит малый бизнес.',
    body: complex_news10,
    picture: require('@/assets/news/news_10.png')
  },

  {
    id: "9",
    date: new Date(2023, 4 - 1, 3),
    title: `Дорогие абоненты! В Личном кабинете теперь можно работать не только с Виртуальной АТС.`,
    link:
      "Удобное управление услугами “Интернет” и “Цифровая телефония” в Личном кабинете.",
    body: complex_news09,
    picture: require("@/assets/news/news_9-convenient-service-management.jpg"),
  },

  {
    id: "8",
    date: new Date(2022, 12 - 1, 29),
    title: `«ОБИТ» пополнил свой портфель услуг сервисом по поддержке еще одного продукта от российского вендора Naumen - платформы для создания интеллектуальных ботов Naumen Erudite.`,
    link:
      "Naumen и «ОБИТ» объединили компетенции в области внедрения отечественного ПО.",
    body: complex_news08,
    picture: require("@/assets/news/news_8-Naumen_&_Obit.jpg"),
  },

  {
    id: "7",
    date: new Date(2022, 11 - 1, 2),
    title: `Kingspan Group - это признанный мировой лидер в области теплоизоляции и ограждающих конструкций для промышленного строительства. Штаб-квартира российского представительства Kingspan находится в Санкт-Петербурге, также компания располагает двумя производственными площадками - в Ленинградской области и Ставропольском крае и офисом продаж в Москве.`,
    link: "Как компания “Kingspan Group” перешла на ВАТС.",
    body: complex_news07,
    picture: require("@/assets/news/news_7-Kingspan-Group.jpg"),
  },

  {
    id: "6",
    date: new Date(2022, 9 - 1, 13),
    title: `“Тенсар Инновэйтив Солюшнз” - крупный производитель материалов для строительства подпорных стен, стабилизации грунта и армогрунтовых решений.`,
    link:
      "Комплексные сервисы Виртуальной АТС для компании “Тенсар Инновэйтив Солюшнз”.",
    body: complex_news06,
    picture: require("@/assets/news/news_6-tensar.jpg"),
  },

  {
    id: "5",
    date: new Date(2022, 6 - 1, 22),
    title: `Оперативные и удобные средства связи стали неотъемлемой частью в решении бизнес-задач.`,
    link: "Расширяем свои сервисы и функционал ВАТС.",
    body: complex_news05,
    picture: require("@/assets/news/news_5-expanding-services-VATS.jpg"),
  },

  {
    id: "4",
    date: new Date(2021, 7 - 1, 6),
    // header: 'Новость 3',
    title: `Делимся с вами обновлениями в Личном кабинете ВАТС:<br/><br/>

1. Добавили кнопку для выгрузки отчетов по входящим и исходящим вызовам.
Теперь вы можете одним кликом формировать отчеты в удобном формате (.xls или .csv) и загружать их в любые системы для анализа.`,
    link: "Личный кабинет ВАТС - что нового?",
    body: complex_news6,
    picture: require("@/assets/news/new2.jpg"),
  },
  {
    id: "1",
    date: new Date(2021, 5, 27),
    // header: 'Новость 3',
    title:
      "Используя номер с кодом набора «8 800», клиенты и партнеры смогут бесплатно позвонить вам из любого региона России со стационарного или мобильного телефона. Это значительно повышает вероятность обращения потенциального клиента именно в вашу компанию, а также лояльность к бренду еще до фактического опыта взаимодействия с ним. Кроме того, бесплатный для клиента звонок дает возможность вашему менеджеру в полном объеме озвучить свое предложение и представить в максимально выгодном свете ваши услуги или товар.",
    link: "Номер 8 800 для бесплатного вызова",
    body: complex_newsFive,
    picture: require("@/assets/news/6.jpg"),
  },
  {
    id: "2",
    date: new Date(2021, 5, 12),
    // header: 'Новость 3',
    title:
      "Главная функция имиджевого или «красивого» номера – остаться в памяти ваших действующих или потенциальных клиентов. По статистике количество входящих звонков на имиджевые номера почти в 6 раз выше, чем на обычный, а легко запоминающийся телефон на баннере или биллборде повышает эффективность рекламы. Удобная комбинация цифр телефонного номера может стать сильным конкурентным преимуществом, если телефон – основной канал приема заказов или первичных обращений.",
    link: "Красивая комбинация цифр как маркетинговый инструмент",
    body: complex_newsFour,
    picture: require("@/assets/news/4.jpg"),
  },
  {
    id: "3",
    date: new Date(2021, 4, 20),
    // header: 'Новость 3',
    title:
      "Тренд на удаленную работу, распределенные команды и гибкий график прочно закрепился в новой бизнес-реальности и стал оптимальным вариантом функционирования для многих компаний. При этом определенные ограничения, связанные с таким форматом, легко преодолеть, используя современные технологии. Виртуальная АТС от «ОБИТ» позволяет закрыть сразу несколько вопросов, связанных с организацией удаленной работы.",
    link: "ВАТС для удаленной работы",
    body: complex_newsThree,
    picture: require("@/assets/news/3.jpg"),
  },
];

export { descr as NEWS_DESCRIPTION, test_data as TEST_NEWS };
