import { CUSTOMER_DESC } from "../components/objects/Customer"
import { EMPLOYEE_DESC } from "../components/objects/Employee"
import { ENTITY_OBJ } from "../components/objects/Entity"
import { DOC_DESC, CALC_DESC, ACT_DESC } from "../components/objects/Bill"
import { EXT_DESC, SERVICE_TYPE_CITY_LINE } from "../components/objects/ExtLine"
import { group_description } from "../components/objects/Group"
import { INSTANCE_DESC } from "../components/objects/Instance"
import { internal_line_desc, PHYSICAL_DESC } from "../components/objects/InternalLine/description"
import { INTEXT_OBJ } from "../components/objects/IntLine"
import { IVR_DESC, test_data_ivr } from "../components/objects/IVR/description"
import { MEDIA_DESC } from "../components/objects/media"
import { test_notifications_data, NOTIFICATIONS_DESC } from "../components/objects/Notifications/description"
import { SERVICE_OBJ } from "../components/objects/Service"
import { SHCH_DESC } from "../components/objects/Schedule"
import { USER_OBJ } from "../components/objects/User"
import { route_description, tags_description, test_rules } from "../components/routing/description"
import { register_comp } from "../object"
import { description as external_line_descr } from '../components/objects/ExtrenalLine/description'
import *  as calls_descs from '../components/calls/description'
import { calls_info, test_address_book } from "../test_data"
import { CONTRACT_DESC } from "../components/objects/Contracts"
import { PERM_DESC, ROLE_DESC } from "../components/objects/Role"
import PBXGroupDesc, { PBXGroupTestData } from '../components/objects/PbxGroup/description'
import { SHCHREF_DESC } from "../components/objects/Schedule/description"
import { DISA_DESC, HANGUP_DESC, PF_DESC, MOH_DESC } from "../components/objects/media/description"
import { SPECS_DESCR } from "../components/objects/Specs/index"
import { outgoingRouteDESC } from "../components/objects/outgoingRoute/index"
import { NEWS_DESCRIPTION, TEST_NEWS } from "../components/objects/News/description"
import { bitrixData } from "../components/objects/integration/index"
import { CALLRECORDS_DESC } from "../components/objects/callRecords/index"


register_comp('user', USER_OBJ)
register_comp('customer', CUSTOMER_DESC)
register_comp('employee', EMPLOYEE_DESC)
register_comp('instance', INSTANCE_DESC)
register_comp('entity', ENTITY_OBJ)
//internal_line: INTEXT_OBJ,
register_comp('service', SERVICE_OBJ)

register_comp(CONTRACT_DESC.base_name, CONTRACT_DESC)

register_comp('internal_line', INTEXT_OBJ)
register_comp('internal_line2', internal_line_desc)
register_comp(SERVICE_TYPE_CITY_LINE, EXT_DESC)

register_comp('notification', NOTIFICATIONS_DESC, test_notifications_data)

register_comp('call', calls_descs.call_description)
register_comp('local_call', calls_descs.local_call_descrption)
register_comp('client_call', calls_descs.client_call_descrption)

register_comp(SPECS_DESCR.obj_type, SPECS_DESCR)

register_comp('log', calls_descs.log_description, calls_info)
register_comp('address_book', calls_descs.address_book_description, test_address_book)
register_comp('change_event', calls_descs.change_events_description, calls_descs.chev_test_data)
register_comp('external_line', external_line_descr)
register_comp('tag', tags_description)

register_comp(outgoingRouteDESC.obj_type, outgoingRouteDESC)

register_comp('role', ROLE_DESC)
register_comp('perm', PERM_DESC)

register_comp('media', MEDIA_DESC)
register_comp(PF_DESC.obj_type, PF_DESC)

register_comp('group', group_description)

register_comp(SHCH_DESC.obj_type, SHCH_DESC)
register_comp(SHCHREF_DESC.obj_type, SHCHREF_DESC)

register_comp(MOH_DESC.obj_type, MOH_DESC)
register_comp(DISA_DESC.obj_type, DISA_DESC)
register_comp(HANGUP_DESC.obj_type, HANGUP_DESC)
register_comp(PHYSICAL_DESC.obj_type, PHYSICAL_DESC)

// ivr
register_comp('ivr', IVR_DESC, test_data_ivr)

register_comp('calculation', CALC_DESC)
register_comp('act', ACT_DESC)
register_comp('bill', DOC_DESC)

register_comp('route_rule', route_description, test_rules)

register_comp('pbx_group', PBXGroupDesc, PBXGroupTestData)
register_comp(NEWS_DESCRIPTION.obj_type, NEWS_DESCRIPTION, TEST_NEWS)
register_comp(bitrixData.obj_type, bitrixData)
register_comp(CALLRECORDS_DESC.obj_type, CALLRECORDS_DESC)
