<template>
  <b-navbar
      v-if="show"
      :class="['topbar', { collapsed: is_collapsed }]"
      variant="white"
      toggleable="lg"
  >
    <b-navbar-brand to="/sip/dash">
      <div class="obit-logo" :class="{'_service': isNotObitLogo}" />
      <span v-if="!isNotObitLogo" class="text-muted varitant-text">Бизнес-телефония</span>
    </b-navbar-brand>

    <div class="ml-auto user-info">
        <span class="font-weight-bold">
          {{ $store.getters.user.profile.name }}
        </span>

        <span class="font-weight-normal mb-2">
          {{$store.getters.user.profile.email}}
        </span>

      <ul class="nav navbar-top-links navbar-right">
        <li>
          <a href="#" @click.prevent="$store.dispatch(`logout`)">
            <icon :icon="icons.sign_out"/> Выход
          </a>
        </li>
      </ul>
    </div>
  </b-navbar>
</template>

<script>
import {
  BNavbar,
  BNavbarBrand,
} from "bootstrap-vue";
import { Icon , as_icon } from '@/components/layot/FontAwesomeIcon.vue';
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "TopBar",
  inject: ["is_vertical"],
  components: {
    Icon,
    BNavbar,
    BNavbarBrand,
  },
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    fixed_type: {
      type: String,
      default: "v2",
    },
    show: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: "default",
    },
    logo_text: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    show_icon() {
      return {
        dash: this.is_vertical.width >= 980,
        info: this.is_vertical.width >= 1024,
      };
    },

    is_collapsed() {
      return !Object.values(this.show_icon).every((r) => r);
    },

		isNotObitLogo() {
			return process.env.VUE_APP_IS_OBIT_LOGO === `false`
		}
  },

  data() {
    return {
      icons: {
        sign_out: as_icon(faSignOutAlt)
      }
    };
  },

  methods: {
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";

.user-info {
  display: flex;
  flex-direction: column;
}

.topbar {
  min-height: 90px;
  max-width: 100vw;
  flex-wrap: nowrap !important;

  &.collapsed {
    .user-dropdown {
      padding-right: inherit !important;
    }
  }

  .obit-logo {
    background-image: url("../../assets/obit24_logo_hor.svg");
    background-repeat: no-repeat;
		background-size: contain;
    width: 5.5rem;
    height: 32px;
    line-height: 4rem;
    @media screen and (min-width: 900px) {
      margin-right: 7.5rem;
    }

		&._service {
			background-image: url("../../assets/it-service_logo.png");
		}
  }

  .top-actions {
    .no-hover {
      background-color: inherit;

      a {
        background-color: inherit;
        cursor: default;
      }
    }

    button,
    a {
      padding-left: 15px;
      padding-right: 15px;
    }

    .user-dropdown {
      padding-right: 30px;

      button {
        &:hover {
          color: $obit-warning;
        }
      }

      ul.dropdown-menu {
        padding: 0;
        margin-top: 20px;
        position: absolute;

        .dropdown-divider {
          border: 0;
        }

        button.logout {
          font-weight: 700;
          font-size: 14px;
          line-height: 16.8px;
          padding: 12px 25px;
        }

        li > * {
          padding: 5px 20px;
          line-height: 24px;
        }

        li:last-child > * {
          padding-bottom: 20px;
        }

        li.info {
          div {
            padding: 20px;
          }
        }
      }
    }
  }

  .varitant-text {
    line-height: 14.4px;
    font-size: 12px;
    font-weight: 400;
    padding-top: 0.5em;
  }
}
</style>