import { buildRelatedField, break_form_field, buildOptionsField, buildPasswordField } from "../fields"
import isActive from '../../shared/activeComp'
import optionsCell from '../../shared/optionsCell'
import { BFormRadioGroup } from "bootstrap-vue"
import { as_icon, Icon } from "../../layot"
import { textBoolInput } from "../../shared"
import CallsLevelInput from './CallsLevelInput'
import devicesInput from './devicesInput'
import EmptyComp from '../Bill/EmptyComp'
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import RecordInput from './RecordInput'
import { id_field_desc, resolveWithPromise, type_val } from "@/functions.js"
import { SERVICE_TYPE_CITY_LINE } from "../ExtLine"
import { internalLineLengthValidator, internalLineValidator, isNumberValidator, positiveValue, subValidator, Validator } from "../../../validators"
import LoginCell from '../IntLine/LoginCell'
import { cloneDeep, merge, pick } from "lodash-es"
import { eventBus } from "../.."
import storage from '../../../store'
import ObitIcon from "../../obit/ObitIcon"
import { SmartButton } from '../../layot'
import TextInput from './TextInput'
import EmployeeInput from './EmployeeInput'
// import TimeoutInput from './TimeoutInput'
import { rebuild_object } from "../../../store/object"
const permissions_options = [
  'Внутренние звонки',
  'Городские',
  'Мобильные региона',
  'Мобильные РФ',
  'Междугородние',
  'Международные',
]

const shevrones = {
  down: as_icon(faChevronDown),
  up: as_icon(faChevronUp)
}
const root = '/pbx/services/v2/internalLine'
const endpointRoot = `${root}/endPoint`
const phyLineRoot = `${root}/physical`


const INTEXT_LINKS = {
  list: `${root}/list`,
  get: `${root}/get`,
  add: `${root}/create`,
  update: `${root}/modify`,
  delete: `${root}/delete`,
  endpoints: {
    sip: {
      add: `${endpointRoot}/createSIP`,
      update: `${endpointRoot}/modifySIP`,
      delete: `${endpointRoot}/deleteSIP`,
    },
    mobile: {
      add: `${endpointRoot}/createExternal`,
      update: `${endpointRoot}/modifyExternal`,
      delete: `${endpointRoot}/deleteExternal`,
    },
    analog: {
      list: `${phyLineRoot}/list`,
      add: `${phyLineRoot}/request`,
      update: `${phyLineRoot}/modify`,
      delete: `${phyLineRoot}/delete`,
    }
  }
}
const service_types = {
  sip: 'internal_line_external_endpoint',
  mobile: 'internal_line_sip_endpoint',
  analog: 'internal_line_physical_endpoint'
}
const service_type_keys = {
  mobile: ['namespace',
    'internal_line_service_id',
    'service_id',
    'disabled',
    'delay',
    'timeout',
    'transferOnTimeout',
    'transferOnBusy',
    'transferAlways',
    'service_type', 'entity_id', 'customer_id',
    'callLevel',
    'target',
    'order',
    'orderTimeout'],
  sip: ['namespace',
    'internal_line_service_id', 'entity_id', 'customer_id',
    'disabled',
    'service_id',
    'delay',
    'timeout',
    'transferOnTimeout',
    'transferOnBusy',
    'transferAlways',
    'login',
    'password',
    'callLevel',
    'allowIP',
    'order',
    'orderTimeout'],
  analog: ['namespace', 'customer_id',
    'internal_line_service_id', 'entity_id',
    'disabled',
    'delay',
    'timeout',
    'service_id',
    'transferOnTimeout',
    'transferOnBusy',
    'caption',
    'transferAlways',
    'login',
    'password',
    'callLevel',
    'allowIP',
    'order',
    'orderTimeout']
}

const isEmployee = process.env.VUE_APP_IS_EMPLOYEE !== `false`
const EMPLOYEE_FIELD_KEY = isEmployee ? `employee` : `caption`

const store = {
  getters: {
    objects: (state, getters, rootState, rootGetters) => {
      let objs = state.objects
      const key = 'instance_id'
      const un = rootGetters['current_instance']
      objs = objs.filter(o => o[key] == un)
      return objs.map(r => getters.restore_object(r))
    },
    pre_data: (state, getters) => (data, type = 'list') => {
      let for_type = getters[`pre_data_${type}`] ? getters[`pre_data_${type}`](data) : {}
      let ret = merge({}, data, getters.unique_for, for_type)
      delete ret['devices']
      return ret
    },
    post_data: (state, getters, rootState, rootGetters) => (data, type = 'list') => {
      let for_type = getters[`post_data_${type}`] ? getters[`post_data_${type}`](data) : {}
      // допишем тестовые данные если есть
      let test_data = getters.test_data
      let ret = merge({}, test_data, data, getters.unique_for, for_type, {
        instance_id: rootGetters.current_instance,
        entity_id: rootGetters.current_entity
      })
      delete ret['namespace']
      return ret
    },
    rebuild_endpoint_from_get: (state, getters, rootState, rootGetters) => (endpoint, data) => {
      const dt = endpoint
      const get_forward_status = (data) => {
        let ret = 0
        if (data.always) { ret = 1 }
        else if (data.onBusy || data.onTimeout) { ret = 2 }
        return ret
      }
      const _device = {
        transfer_always: dt?.transfer.always || null,
        transfer_busy: dt?.transfer.onBusy || null,
        transfer_timeout: dt?.transfer.onTimeout || null,
        forward: get_forward_status(dt.transfer),
        service_id: dt.service_id,
        service_type: dt.service_type,
        state: dt.state.availableToUse,
        namespace: rootGetters.current_namespace,
        internal_line_service_id: data.service_id,
        _raw: dt,
        disabled: !dt.restrictions.disabled,
        block_type_change: true,
        callLevel: dt.restrictions.callLevel || 0,
        entity_id: rootGetters.current_entity,
        order: dt?.order.order,
        orderTimeout: dt?.order.orderTimeout || 20,
        _state: 'existed'
      }
      let device = {}
      if (dt.service_type == 'internal_line_sip_endpoint') {
        const login = dt.sip.login
        const account = `${dt.sip.real_login}@${dt.sip.server}`
        device = merge({
          type: 'sip',
          login,
          password: dt.sip.password,
          account,
          allowIP: dt.restrictions.IP
        }, _device)
      } else if (dt.service_type == 'internal_line_external_endpoint') {
        device = merge(_device, {
          type: 'mobile',
          target: dt.target,
          callLevel: Math.max(_device.callLevel, 4)
        })
      } else if (dt.service_type == 'internal_line_physical_endpoint') {
        device = merge({
          type: 'analog',
          caption: dt.caption
        }, _device)
      }
      return device
    },
    post_data_get: (state, getters) => (data) => {
      let ret = {}
      // восстановим устройства
      let devices = data.endPoints.map(
        endpoint => getters.rebuild_endpoint_from_get(endpoint, data)
      )
      // вычислим общий уровень звонков 
      let base_call_level = devices.filter(d => d.type != 'mobile').reduce((r, e) => r = Math.max(r, e.callLevel), 0)
      if (devices.length && !devices.filter(d => d.type != 'mobile').length) {
        // есть только мобильный
        base_call_level = devices.reduce((r, e) => r = Math.max(r, e.callLevel), 4)
      }
      ret['devices'] = devices
      ret['devices_count'] = devices.length
      ret['strategy'] = data.callProperties.strategy
      ret['tarrif'] = data.callProperties.isMonitor
      ret['outgoingRoute'] = data.callProperties.outgoingRoute
      ret['call_perms'] = base_call_level
      ret['orderTimeout'] = devices[0]?.orderTimeout || 0
      ret['monitor'] = data.callProperties.isMonitor
      // порядок дозвона
      return ret
    },
    post_data_list: () => (data) => {
      let ret = {}
      // восстановим устройства
      ret['strategy'] = data.callProperties.strategy
      ret['monitor'] = data.callProperties.isMonitor
      ret['tarrif'] = data.callProperties.isMonitor
      ret['outgoingRoute'] = data.callProperties.outgoingRoute
      // порядок дозвона
      return ret
    },
    build_changed_endpoints: (state, getters) => data => {
      let update = [], deleted = [], add = []
      let endpoints = cloneDeep(data.devices || [])
      const current_call_perms = data.call_perms || null
      if (current_call_perms !== null) {
        for (let device of endpoints) {
          device['callLevel'] = current_call_perms
          if (device._state != 'new' && device._state != 'deleted') {
            device._state = 'edited'
            if (device.type == 'mobile') {
              if (device.callLevel === 0) {
                device.callLevel = 4
              } else if (device.callLevel) {
                device.callLevel = Math.max(device.callLevel, 4)
              }
            }
          }
        }
      }
      add = endpoints.filter(e => e._state === 'new').map(
        e => merge({
          service_type: service_types[e.type], internal_line_service_id: data.service_id,
          callLevel: e.type == 'mobile' ? Math.max(data.call_perms, 4) : data.call_perms  // внешняя терминация - минимальный уровень - 4
        }, e, getters.unique_for)
      )
      deleted = endpoints.filter(e => e._state === 'deleted').map(
        e => merge({ internal_line_service_id: data.service_id }, getters.unique_for, e)
      )
      // оставшиесу
      update = endpoints.filter(e => e._state === 'edited').map(
        e => {
          let ret = { ...e, disabled: !e.disabled, ...getters.unique_for }
          const fields_map = {
            sip: {
              transfer_always: 'transferAlways',
              transfer_busy: 'transferOnBusy',
              transfer_timeout: 'transferOnTimeout',
            },
            analog: {
            },
            mobile: {
            }
          }
          for (const [inner, outer] of Object.entries(fields_map[e.type])) {
            ret[outer] = type_val(ret[inner]) == 'String' ? ret[inner].trim() : ret[inner]
            delete ret[inner]
          }
          return ret
        }
      )

      // обход старых
      const ret = { update, delete: deleted, add }
      return ret
    }
  },
  mutations: {
    update: (state, object) => {
      const obj = rebuild_object(object, state.description)
      const key = state.description.obj_key
      const obj_key = obj[key] || obj.obj_id
      let old_obj = state.objects.find(o => o[key].toLowerCase() === obj_key.toLowerCase()) || {}
      if (obj.devices) {
        old_obj.devices = []
      }
      const upd_obj = merge(
        old_obj,
        obj
      )
      state.objects = state.objects.filter(
        o => o[key].toLowerCase() != obj_key.toLowerCase()
      ).concat([upd_obj])
      eventBus.$emit(`updated:${upd_obj.obj_type}:${upd_obj.obj_id}`)
    },
  },
  actions: {
    update({ getters, commit, dispatch }, object) {
      const get_api = getters.has_api.update
      const splited_data = getters.build_changed_endpoints(object)
      const upd = d => {
        if (d.data.result == 'success') {
          commit('update', getters.post_data(d.data.payload, 'update'))
        }
        return d.data.result == 'success'
      }
      return dispatch(
        'requestApi',
        {
          url: get_api,
          data: getters.pre_data(object, 'update')
        }, { root: true }
      ).then(upd).then(
        updated => {
          if (updated) {
            let actions = []
            Object.entries(splited_data).map(
              ([action, endpoints]) => {
                for (const endpoint of endpoints) {
                  const url = INTEXT_LINKS.endpoints[endpoint.type][action]
                  let d = pick(endpoint, service_type_keys[endpoint.type])
                  if (action == 'delete') {
                    d = pick(d, ['service_id', 'customer_id', 'entity_id', 'internal_line_service_id'])
                  }
                  if (url) {
                    actions.push(
                      dispatch(
                        'requestApi',
                        {
                          url: url,
                          data: d
                        },
                        {
                          root: true
                        }
                      )
                    )
                  }
                }
              }
            )
            return Promise.all(actions)
          } else commit('add_message', { type: 'danager', massage: 'Ошибка при обновлении' }, { root: true })
        }
      ).then(() => dispatch('get', object.service_id))
    },
    add({ getters, commit, dispatch, rootGetters }, object) {
      const url = getters.has_api.add
      let add_data = getters.pre_data(object, 'add')
      let req = resolveWithPromise(add_data.employee)
      let r = undefined

      if (isEmployee) {
        if (!add_data.employee) {
          req = dispatch('employee/add', {
            title: `Сотрудник ${add_data.number}`
          }, { root: true }).then(empl => empl.employee_id)
        }
      }

      if (!add_data.outgoingRoute) {
        if (rootGetters['out_route/objects'].length) {
          add_data.outgoingRoute = rootGetters['out_route/objects'][0].obj_id
        }
      }
      return req.then((d) => {
        if (isEmployee) {
          add_data[`employee`] = d
        }

        return dispatch(
          'requestApi',
          {
            url: url,
            data: add_data,
            withFiles: getters.description.withFiles || false
          }, { root: true }
        )
      }).then(
        d => {
          let ret = undefined
          if (d?.isAxiosError) {
            commit('add_message', { message: d.response.data.text, type: 'danger' }, { root: true });
            return null
          }
          if (d.data.result == 'success') {
            const record = getters.post_data(d.data.payload, 'add')
            r = record
            commit('update', record)
            eventBus.$emit(`added:${record.obj_type}`, record)
            ret = record
          }
          return ret
        }
      ).then(
        record => {
          if (record) {
            // создадим
            const service_id = record.service_id
            let actions = []
            for (const endpoint of object.devices) {
              const url = INTEXT_LINKS.endpoints[endpoint.type].add
              const d = pick(merge({
                internal_line_service_id: service_id,
                ...getters.unique_for
              }, endpoint, { callLevel: object.call_perms }), service_type_keys[endpoint.type])
              if (url) {
                actions.push(
                  dispatch(
                    'requestApi',
                    {
                      url: url,
                      data: d
                    },
                    {
                      root: true
                    }
                  )
                )
              }
            }
          }
        }
      ).then(() => dispatch('list', { force: true })).then(() => r)
    },
  }
}
const numberExist = number => {
  const message = 'Номер занят';
  const numbers = storage.getters['pbx_group/objects'].map(i => i.number) + storage.getters['internal_line2/objects'].map(i => i.number);
  return subValidator(
    numbers.indexOf(number) > -1, message
  )
}

const get_devices = item => {
  let ret = item.devices || []
  if (type_val(ret) == 'String') {
    ret = []
  }
  return ret
}

const fields = {
  service_id: id_field_desc,
  entity_id: buildRelatedField(
    'Договор', 'entity', { editable: false }
  ),
  instance_id: buildRelatedField(
    'Инстанс', 'instance', { editable: false }
  ),
  out: buildRelatedField(
    'Внешняя линий', SERVICE_TYPE_CITY_LINE
  ),
  number: {
    label: 'Внутренний номер',
    editable: false,
    isKey: true,
    required: true,
    comp: TextInput,
    validator: (val, obj) => Validator(
      val, obj, isNumberValidator, internalLineLengthValidator, internalLineValidator, positiveValue,
      numberExist
    ),
    list_options: { // опциональные папаметры для b-table
      sortable: true,
      root: '/sip/internal_line2'
    },
    // description: 'Не может начинаться с единицы',
    question: 'Для звонков внутри Вашей Виртуальной АТС, переадресации звонков и распределения вызовов',
    options: {
      placeholder: 'XXX',
      text: 'Используйте любую комбинацию из 3-5 цифр, которая не начинается с 1 и 0'
    },
  },
  login: {
    label: 'Логин',
    cell: LoginCell,
    list_options: { // опциональные папаметры для b-table
      sortable: true,
      // formatter: (value, key, obj) => {
      //     //console.debug('_frmtr', obj, key, value)
      //     const ent = obj._related('entity_id')
      //     const name = obj.obj_name
      //     let ret = name
      //     if (ent._ready) {
      //         ret = `${ent.obj_name}+${name}`
      //     }
      //     return ret
      // }
    }
  },
  devices: {
    multiple: true
  },
  strategy: 'Проядок дозвона',
  password: buildPasswordField('Пароль'),
  // transfer_always: buildRelatedField('Постоянная переадресация', 'internal_line'),
  // transfer_busy: buildRelatedField('Переадресация при занятой линии',  'internal_line'),
  // transfer_timeout: buildRelatedField('Переадресация при таймауте', 'internal_line'),
  caption: {
    label: 'Сотрудник',
    comp: EmployeeInput,
  },
  employee: buildRelatedField(
    'Сотрудник', 'employee',
    {
      required: false,
      options: {
        options: () => store.getters['employee/objects'].filter(e => e.status != 'deleted'),
        addButton: true,
        new_fields: ['title'],
        modalProps: {
          title: 'Создать нового пользователя',
          'ok-title': 'Создать'
        }
      }
    }),
  external_line_in: buildRelatedField('Входящие', 'city_number'),
  outgoingRoute: buildRelatedField('Номер для исходящих', 'out_route', { question: 'Данный номер отображается на телефоне, на который звонит сотрудник. Также этот номер определяет регион звонка для расчета стоимости связи.' }),
  call_perms: buildOptionsField(
    'Разрешения', permissions_options,
    {
      comp: CallsLevelInput,
      // required: true,
      options: {
        stacked: true
      },
    }
  ),
  tariff: {
    label: 'Тарификация',
    table_formatter: (f, k, item) => {
      return item
    }
    //test_value: 'Включена'
  },
  rules: {
    label: 'Учавствует в правилах',
    test_value: 'Техподдержка'
  },
  additional_settings: {
    label: 'Расширенные настройки',
    test_value: false,
    row_props: {
      'label-class': 'font-weight-bold',
      'label-cols': 4
    },
    comp: textBoolInput,
    options: {
      false_text: 'Развернуть',
      true_text: 'Свернуть',
      false_comp: { comp: Icon, props: { icon: shevrones.down } },
      true_comp: { comp: Icon, props: { icon: shevrones.up } }
    }
  },
  transfer: {
    label: ' ',
  },
  transfer_always: {
    label: 'Постоянная переадресация',
    options: { placeholder: 'Введите номер телефона' }
  },
  transfer_busy: {
    label: 'Переадресация при занятой линии',
    options: { placeholder: 'Введите номер телефона' }
  },
  transfer_timeout: {
    label: 'Переадресация при таймауте',
    options: { placeholder: 'Введите номер телефона' }
  },
  // timeout_order: {
  //   label: 'Время перехода, cек',
  //   target: 'orderTimeout',
  //   comp: TimeoutInput,
  //   rule: item => item.strategy == 'S'

  // },
  monitor: {
    label: 'Запись разговора', comp: RecordInput,
  },
}

const view_fields = [
  {
    target: 'number',
    label: 'Номер',
    description: null,
    editable: false
  },

  // @TODO убирает поле с employee из внутренних звонков
  // buildRelatedField(
  //   'Имя', EMPLOYEE_FIELD_KEY,
  //   {
  //     target: EMPLOYEE_FIELD_KEY,
  //     options: {
  //       options: () => (storage.getters['employee/objects'] || []).filter(e => !e.deleted),
  //       addButton: true,
  //       modalProps: {
  //         title: 'Создать нового пользователя',
  //         'ok-title': 'Создать'
  //       },
  //       new_fields: ['title']
  //     },
  //   }
  // ),
  break_form_field,
  {
    label: ' ',
    key: 'devices',
    target: 'devices',
    comp: devicesInput,
    // validator: item => 
    row_props: {
      cols: 0,
    }
  },
  {
    label: 'Порядок дозвона',
    comp: BFormRadioGroup,
    target: 'strategy',
    test_value: null,
    options: {
      stacked: true,
      options: {
        P: 'Параллельный',
        S: 'Последовательный'
      }
    },
    rule: item => item.devices_count > 1 //get_devices(item).filter(i => i._state != 'deleted').length > 1
  },
  // {
  //   label: 'Время перехода, cек',
  //   target: 'orderTimeout',
  //   comp: TimeoutInput,
  //   rule: item => item.strategy == 'S' && item.devices_count > 1 // get_devices(item).filter(i => i._state != 'deleted').length > 1

  // },
  break_form_field,
  {
    key: 'outgoingRoute',
    target: 'outgoingRoute',
    rule: item => !(item.devices_count == 1 && item.devices[0].type == 'mobile')
  },
  {
    label: 'Разрешения',
    target: 'call_perms',
    key: 'call_perms',
    // required: true,
    rule: item => !(item.devices_count == 1 && item.devices[0].type == 'mobile'),
    validator: (value, item) => (value < 2 && !(item.devices_count == 1 && item.devices[0].type == 'mobile')) ? ['Обязательный параметр'] : null
  },
  'monitor'
]

const EMPLOYEE_FIELD = isEmployee
    ? buildRelatedField(
    'Имя', EMPLOYEE_FIELD_KEY,
    {
      target: EMPLOYEE_FIELD_KEY,
      options: {
        options: () => (storage.getters['employee/objects'] || []).filter(e => !e.deleted),
        addButton: true, new_fields: ['title'],
        modalProps: {
          title: 'Создать нового пользователя',
          'ok-title': 'Создать'
        }
      },
      func(obj) {
        return {
          placeholder: `Сотрудник ${obj.number || 'XXX'}`
        }
      }
    })
    : {
      label: `Сотрудник`,
      target: EMPLOYEE_FIELD_KEY,
      editable: false,
      isKey: true,
      required: true,
      comp: EmployeeInput
    }

const create_field = [
  'number',
  EMPLOYEE_FIELD,
  break_form_field,
  {
    label: ' ',
    target: 'devices',
    comp: devicesInput,
    row_props: {
      'label-cols': 0
    },
    rule: item => ['', ' ', null, undefined].indexOf(item.number || null) == -1
  },
  'additional_settings',
  {
    label: 'Порядок дозвона',
    comp: BFormRadioGroup,
    target: 'strategy',
    test_value: null,
    options: {
      stacked: true,
      options: {
        P: 'Параллельный',
        S: 'Последовательный'
      }
    },
    rule: item => item.additional_settings == true && get_devices(item).length >= 2
  },
  // {
  //   label: 'Время перехода, сек',
  //   target: 'orderTimeout',
  //   comp: TimeoutInput,
  //   rule: item => item.strategy == 'S'
  // },
  {
    key: 'outgoingRoute',
    target: 'outgoingRoute',
    rule: item => item.additional_settings == true,
    options: {

      defaultFirstAvailable: true
    }
  },
  // {
  //   key: 'call_perms',
  //   target: 'call_perms',
  //   required: true,
  //   rule: item => item.additional_settings == true,
  //   validator: value => value < 2 ? ['Обязательный параметр'] : null
  // },
  {
    key: 'monitor',
    target: 'monitor',
    rule: item => item.additional_settings == true,
  }
]
// const build_link = (item, query = {}) => {
//   return {
//     name: 'object', params: { type: item.obj_type || item._description.obj_type, id: item.obj_key },
//     query: query
//   }
// }

const link_with_params = (item, params = {}) => {
  let query = Object.entries(params).reduce(
    (ret, [key, val]) => (ret += `${key}=${val}&`),
    ""
  );
  return `/sip/${item.obj_type}/${item.obj_id}?${query}`
}

const internal_line_desc = {
  verbose: 'Внутренний номер',
  plural: 'Внутренние номера',
  no_create_button: true,
  autocomplete_off: true,
  // create_button_text: 'Создать нового пользователя',
  create_button: SmartButton,
  create_button_props: {
    osnPart: 'Создать',
    osnIcon: ObitIcon,
    osnIconProps: { icon: 'plus' },
    secondPart: 'Внутренний номер'
  },
  obj_name: 'number',
  obj_template: obj => {
    let name = `${obj.number}`

    if (isEmployee) {
      const employee = obj._related(`employee`)

      if (employee) {
        name = `${obj.number} - ${employee.obj_name}`
      }
    }
    return name
  },
  obj_keye: 'service_id',
  delete_key: 'date_stop',
  //obj_comp: InternalLineObj,
  urls: INTEXT_LINKS,
  storage: store,
  hide_modal_header: true,
  fields: fields,
  page_variant: 'sip',
  unique_for: ['customer', 'instance', 'entity'],
  empty_comp: EmptyComp,
  //related: ['callRecords'],
  list_fields_search: ['number', EMPLOYEE_FIELD_KEY],
  save_method: (comp) => {
    if (comp.$refs.new_form && comp.$refs.new_form.validate()) {
      comp.$bvModal.msgBoxConfirm('Стоимость нового внутреннего номера составляет 200 руб./мес без НДС. Продолжить?', {
        okVariant: 'obit',
        okTitle: 'Подтвердить',
        cancelTitle: 'Отмена',
        hideHeaderClose: false,
        centered: true
      }).then(
        ok => {
          if (ok) comp.$emit('save')
        }
      )
    } else {
      comp.$emit('save')
    }
  },
  list_fields: [
    'number',
    EMPLOYEE_FIELD_KEY,
    'outgoingRoute',
    { label: 'Cтатус', key: 'isActive', cell: isActive, target: 'state.availableToUse', cell_options: { rule: (v) => v ? true : false } },
    { label: 'Запись звонков', key: 'tariff', formatter: (f, k, item) => item?.callProperties?.isMonitor ? 'Включена' : 'Выключена' },
    {
      label: '', key: 'actions', cell: optionsCell, cell_options: {
        actions: [
          { text: 'Информация', rule: comp => { return { to: link_with_params(comp.item, { page: 'info' }) } } },
          { text: 'Журнал вызовов', rule: comp => { return { to: link_with_params(comp.item, { page: 'calls' }) } } },
          { text: 'История операций', rule: comp => { return { to: link_with_params(comp.item, { page: 'history' }) } } },
          // {
          //   text: 'Удалить',
          //   rule: () => ({ variant: 'danger' }),
          //   action: item => {
          //     storage.commit('add_message', { type: 'danger', message: `попытка номер удалить ${item.obj_name}` })
          //     storage.dispatch('internal_line2/delete', item.service_id)
          //   }
          // }
        ]
      }
    }
  ],
  new_fields: create_field,
  view_fields: view_fields,
  edit_fields: view_fields
}

export { PHYSICAL_DESC } from './phys_description'
export { internal_line_desc }