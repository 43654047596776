import {BFormInput, BFormSelect} from 'bootstrap-vue'
import template from '../../../template'
import Resource from './Resource'
import { actions_field } from '../../../functions'
const root = 'service'
const SERVICE_LINKS = {
    get: `${root}/get`,
    update: `${root}/update`,
    add: `${ root }/add`,
    delete: `${root}/close`,
    //list: `${ root }/list`,
}


const services = {
    city_number: 'Городской номер',
    external_line: 'Внешняя линия',
    internal_line: 'Внутренняя линия',
    recorder: 'Запись разговоров',
    redirect: 'Переадресация',
    conference_room: 'Конференц-комната',
    IVR: 'IVR',
    MOH: 'Мелодия на удержание',
}
const AVAL_SERVICES = Object.entries(services).map(value => { return { value: value[0], text: value[1] } })
const active_services = ['internal_line']
const SERVICE_OBJ = {
    urls: SERVICE_LINKS,
    obj_key: 'service_id',
    obj_name: 'service_id',
    delete_key: 'date_stop',
    obj_template: template`${'service_type'}: ${'entity_id'}`,
    verbose: 'Услуга',
    //depences: ['entity'],
    plural: 'Услуги',
    next_new_url: '/service/',
    editable: false,
    next_edit_url: NaN,
    new_default: {},
    unique_for_customer: true,
    //custom_list_func: get_objects,
    fields: {
        "service_type": {
            label: "Тип",
            comp: BFormSelect,
            options: {
                options: AVAL_SERVICES
            },
            editable: false
        },
        "entity_id": {
            label: "Договор",
            comp: BFormSelect,
            related: 'entity',
            options: 'calc',
            editable: false
        },
        "date_start": {
            label: "Начало",
            comp: BFormInput,
            options: {
                type: 'datetime',
                disabled: false
            }
        },
        "date_stop": {
            label: "Конец",
            comp: BFormInput,
            options: {
                type: 'datetime',
                disabled: false
            }
        },
        resources: {
            comp: BFormInput,
            edit_comp: Resource,
            view_comp: Resource,
            rule: obj => obj['service_type'] == 'internal_line',
            default: 0,
            show: false,
            label: 'Номер',
            target: 'resources',
            multiple: true,
            options: {
                type: 'number'
            }
        }

    },
    list_fields: ['service_type', 'entity_id', 'date_start', 'date_stop', actions_field],
    list_fields_search: ['service_type', 'entity_id'],
    edit_fields: [
        'service_type', 'entity_id',
        'resources'
    ],
    new_fields: [
        'service_type', 'entity_id', 'resources',
    ]
}
export {SERVICE_LINKS, SERVICE_OBJ, services, AVAL_SERVICES, active_services}