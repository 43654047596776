<template>
  <div class="bg-white routing-page" :class="{ add_item: block_everything }">
    <h1 class="help">
      Нажмите левой кнопкой мыши в том месте, где хотите разместить элемент
    </h1>
    <div class="foreground" v-if="reloading">
      <div class="spinner">
        <b-spinner class="sp" variant="warning" label="Spinning" />
      </div>
    </div>
    <div :class="{ 'editor-controls': true, openned: !readonly }">
      <b-button-group class="controls" size="lg">
        <b-button
          @click="toggle_editor"
          :variant="!readonly ? 'warning' : 'obit'"
          v-b-tooltip="{
            title: readonly ? 'Редактировать' : 'Закончить редактирование',
            variant: 'light',
            placement: 'bottom',
            delay: 1000,
            boundary: 'window',
          }"
        >
          <icon :icon="icons.edit" v-if="readonly" />
          <icon-stack v-else>
            <icon :icon="icons.edit" />
            <icon :icon="icons.close" />
          </icon-stack>
        </b-button>

        <b-button
          @click.exact="do_reload"
          @click.ctrl="do_restart"
          :variant="ctrl_mode ? 'outline-danger' : 'outline-warning'"
          class="border-0"
          v-if="has_changed_data"
          v-b-tooltip="{
            title: ctrl_mode ? 'Перезагрузить' : 'Отменить все изменения',
            variant: 'light',
            placement: 'bottom',
          }"
        >
          <icon :icon="ctrl_mode ? icons.reload : icons.undo" />
        </b-button>

        <b-button
          @click="save_data(true)"
          variant="outline-success"
          class="border-0"
          v-if="has_changed_data"
          v-b-tooltip="{
            title: 'Сохранить',
            variant: 'light',
            placement: 'bottom',
          }"
        >
          <icon :icon="icons.save" />
        </b-button>
        <!-- <b-button v-if="!readonly" variant="outline" @click="sidebar = true"
            ><icon :icon="icons.bars"
          /></b-button> -->
      </b-button-group>

      <b-button-group class="controls" size="lg">
        <b-button
          v-if="!search_enabled"
          variant="outline-primary"
          class="border-0"
          @click="search_enabled = true"
          size="lg"
        >
          <icon :icon="icons.search" />
        </b-button>
        <fragment v-else>
          <multiselect
            v-model="search_types"
            :options="add_options"
            class="search-bar"
            :multiple="true"
            label="text"
            @remove="del_from_seach_type"
            @input="search_items()"
            @tag="search_tag"
            :placeholder="searh_placeholder"
            selectLabel="Искать"
            selectedLabel="Выбрано"
            deselectLabel="Убрать"
            tagPlaceholder="Искать"
            trackBy="type"
            taggable
          >
            <template v-slot:option="{ option }">
              <span v-if="option.text">
                <icon :icon="option.icon" /> {{ option.text }}
              </span>
              <span v-else>
                {{ option.label }}
              </span>
            </template>
            <template v-slot:tag="{ option, remove }">
              <b-badge
                v-if="option.type != '__search__'"
                variant="primary"
                class="tag-item"
                @click="remove(option)"
                v-b-tooltip="{ title: option.text, variant: 'light' }"
              >
                <icon :icon="option.icon" />
                <!-- <span @click="remove(option)" class="tag-item-remove">
                
              </span> -->
                <icon class="close-icon" :icon="icons.close" />
              </b-badge>
              <b-badge v-else class="tag-item text" @click="remove(option)">
                {{ option.text }}
                <icon class="text-danger" :icon="icons.close" />
              </b-badge>
            </template>
          </multiselect>
          <b-button
            variant="outline-primary"
            class="border-0"
            size="lg"
            @_click="search_items()"
            @click="
              search_string = '';
              search_types.length = 0;
              search_items();
              search_enabled = false;
            "
          >
            <icon :icon="icons.close" />
          </b-button>
        </fragment>
      </b-button-group>
      <!-- <b-button v-else variant="outline-primary" @click="zoom_enabled = true">
          <icon :icon="icon.zoom_in" />
        </b-button> -->
    </div>

    <b-button-group class="zoom-panel">
      <fragment v-if="zoom_enabled">
        <b-button variant="outline-primary" @click="do_zoom(-10)">
          <icon :icon="icons.zoom_out" />
        </b-button>
        <b-button v-if="!edit_zoom" variant="outline" @click="edit_zoom = true"
          >{{ zoom_as_string }} %
        </b-button>
        <b-input
          v-else
          v-model="zoom_as_string"
          type="number"
          class="custom_zoom"
          autofocus
          lazy
          @keypress.enter="edit_zoom = false"
          @blur="edit_zoom = false"
          @formchange="edit_zoom = false"
          :min="1"
        />
        <b-button variant="outline-primary" @click="do_zoom(10)">
          <icon :icon="icons.zoom_in" />
        </b-button>
        <b-button variant="outline-primary" @click="zoom_enabled = false">
          <icon :icon="icons.close" />
        </b-button>
      </fragment>
      <b-button v-else variant="outline-primary" @click="zoom_enabled = true">
        <icon :icon="icons.zoom_in" /> {{ zoom_as_string }} %
      </b-button>
    </b-button-group>

    <div
      class="items-paletre"
      v-if="!readonly"
      :style="{ '--hover-size': tools_size }"
      @mouseover="toggle_hover(true)"
      @mouseleave="toggle_hover(false)"
    >
      <b-button-group size="lg" ref="items">
        <b-button
          v-for="item in add_options"
          :key="item.type"
          :variant="show_options == item.type ? 'obit' : 'outline-primary'"
          class="border-0"
          size="lg"
          @click="toggle_options(item.type)"
          ref="items_buttons"
          v-b-tooltip="{
            title: item.text,
            variant: 'light',
            trigger: 'hover',
            placement: 'right',
            boundary: 'window',
            container: $refs.items,
            delay: 1000,
          }"
        >
          <obit-icon v-if="item.obit_icon" :icon="item.icon" />
          <icon v-else :icon="item.icon" />
          <b-badge
            variant="warning"
            size="sm"
            v-if="avalable_options[item.type]"
            >{{ avalable_options[item.type] }}</b-badge
          >
          <b-badge v-if="item.unknown" variant="danger" class="unknown"
            >beta</b-badge
          >
          <!-- <b-badge
          variant="primary"
          v-if="item.has_add"
          v-b-tooltip="{ title: 'Добавить новый', variant: 'light' }"
          @click="show_add_modal(item)"
          >+</b-badge
        > -->
        </b-button>
      </b-button-group>
      <b-button-group
        v-if="show_options"
        class="options"
        v-clickoutside="{
          handler: toggle_options_outside,
          exclude: [$refs.items],
        }"
      >
        <b-button
          v-if="show_options_obj.has_add"
          variant="outline"
          class="border-0"
          @click="show_add_modal(show_options_obj)"
          v-b-tooltip="{
            title: 'Добавить новый',
            variant: 'light',
            placement: 'top',
          }"
        >
          + {{ show_options_obj.text }}
        </b-button>
        <b-button v-else variant="outline">
          {{ show_options_obj.text }}
        </b-button>
        <b-input-group v-if="(available_nodes[show_options] || []).length > 6">
          <b-input v-model="show_options_search" placeholder="Поиск" />
          <b-input-group-append v-if="show_options_search.length">
            <b-button
              variant="outline-primary"
              @click="show_options_search = ''"
            >
              <icon :icon="icons.close" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-button
          class="border-0"
          v-for="obj in avalable_options_current"
          :key="obj.service_id"
          variant="outline-primary"
          @click="show_node(obj.obj_id)"
        >
          {{ get_add_node_name(obj) }}
        </b-button>
        <b-button
          class="border-0 no-options"
          v-if="!avalable_options_current.length"
          disabled
          variant="outline"
        >
          Пусто
        </b-button>
      </b-button-group>
    </div>

    <div id="rete" ref="rete" :class="{ edit: !readonly }" />
    <b-modal
      lazy
      :title="`Добавить ${add_title}`"
      v-model="show_add"
      @ok="close_modal()"
    >
      <obj
        v-model="new_obj"
        :obj_type="type"
        mode="new"
        :description="typ_descr"
        ref="add_modal"
      />
    </b-modal>
    <b-modal lazy title="Сохранить" v-model="show_save" @ok="save_data()">
      Есть несохраненные изменения. Нажмите "Сохранить", чтобы применить все
      изменения и перейти к созданию нового элемента "{{ type_name }}"
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import Rete from "rete";
import VueRenderPlugin from "rete-vue-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import ReteAutoArangePlugin from "rete-auto-arrange-plugin";
import MinimapPlugin from "./minimap-plugin";
import AreaPlugin from "rete-area-plugin";
import ReadOnlyPlugin from "rete-readonly-plugin";
// import ContextMenuPlugin from "rete-context-menu-plugin";
import { ComponentsClasses } from "./components";
//import ContextMenu from "./components/ContextMenu";
import { rebuild_data, pack_data } from "./rebuild_data";
import { bindEvent, bindNativeEvent } from "../../../functions";
import { as_icon } from "../../layot/FontAwesomeIcon.vue";

import Multiselect from "vue-multiselect";
import {
  faBars,
  faCalendarAlt,
  faChevronDown,
  faChevronUp,
  faFax,
  faPhoneSlash,
  faPhoneVolume,
  faPlayCircle,
  faProjectDiagram,
  faQuestion,
  faSave,
  faSync,
  faUser,
  faUsers,
  faTrash,
  faSearchPlus,
  faSearchMinus,
  faSearchLocation,
  faTimes,
  faPenFancy,
  faUndo,
  //faInbox,
  //faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Fragment, Icon, IconStack } from "../../layot";
import { eventBus, ObjForm } from "../..";
import store from "../../../store";
import { cloneDeep, debounce, findIndex, merge } from "lodash-es";
import MiniMapVue from "./components/MiniMap.vue";
import clickoutside from "../../../directives/click_outside";
import ObitIcon from "../../obit/ObitIcon.vue";

const editor_name = "routing@0.1.0";

const IGNORE_UNKNOWN = ["ScheduleReference", "internal_line_physical_endpoint"];
export default {
  name: "RouterEditor",
  components: {
    Icon,
    obj: ObjForm,
    Fragment,
    Multiselect,
    IconStack,
    ObitIcon,
  },
  directives: { clickoutside },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    additional_data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      original_data: 0,

      zoom: 0,
      editor: null,
      engine: null,

      show_add: false,
      show_save: false,
      aval_to_add: [],
      add_title: "",
      readonly: true,
      loaded: false,
      has_changed_data: false,
      sidebar: false,
      type: null,
      show_options: null,
      show_options_obj: null,
      show_options_search: "",
      new_obj: {},
      type_name: "",
      show_item: null,

      block_everything: false,
      zoom_enabled: false,
      edit_zoom: false,

      search_enabled: false,
      search_string: "",
      search_types: [],

      ctrl_mode: false,
      reloading: false,

      tools_size: "4em",
    };
  },
  async mounted() {
    // компоненты
    var components = ComponentsClasses;
    // редакктор
    var editor = new Rete.NodeEditor(editor_name, this.$refs.rete);
    // планины
    bindNativeEvent(this, window, "keydown", (event) => {
      if (event.code == "ControlLeft" || event.code == "ControlRight") {
        this.ctrl_mode = true;
      }
    });
    bindNativeEvent(this, window, "keyup", (event) => {
      if (event.code == "ControlLeft" || event.code == "ControlRight") {
        this.ctrl_mode = false;
      }
    });

    editor.use(ConnectionPlugin);
    editor.use(VueRenderPlugin, { options: { store } });
    // editor.use(ContextMenuPlugin, {
    //   searchBar: false,
    //   vueComponent: ContextMenu,
    // });
    editor.use(MinimapPlugin, { vueComponent: MiniMapVue });
    editor.use(AreaPlugin);
    editor.use(ReteAutoArangePlugin, { margin: { x: 70, y: 20 }, depth: 0 });
    var engine = new Rete.Engine(editor_name);
    // "engine"
    components.map((comp) => {
      let c = new comp();
      editor.register(c);
      engine.register(c);
    });

    editor.on(
      "arrange process nodecreated noderemoved connectioncreated connectionremoved",
      async () => {
        await engine.abort();
        await engine.process(editor.toJSON());
      }
    );
    // отследим создание/удаление связей
    editor.on("connectioncreated connectionremoved", () => {
      if (this.loaded) {
        this.has_changed_data = true;
      }
    });
    // отловим перемещения
    const debSave = debounce((node) => {
      this.$store.dispatch("routing/position", node);
      if (!this.readonly && !this.has_changed_data) {
        this.has_changed_data = true;
      }
    }, 200);
    const debTransform = debounce((data) => {
      this.$store.dispatch("routing/zoom", data);
      this.zoom = data.k;
    }, 400);
    editor.on("nodetranslated", async ({ node }) => {
      await debSave(node);
    });

    editor.on("zoom translate", ({ transform, source }) => {
      if (source == "dblclick") {
        return false;
      }
      debTransform(transform);
    });

    const dt = {
      id: editor_name,
      nodes: rebuild_data(this.data, this.additional_data),
    };

    this.original_data = cloneDeep(dt);

    await editor.fromJSON(dt);

    editor.use(ReadOnlyPlugin, { enabled: true });

    if (!this.$store.getters["routing/has_transform"]) {
      editor.view.resize();
      AreaPlugin.zoomAt(editor);
    } else {
      const { area } = editor.view;
      const { k, x, y } = this.$store.getters["routing/zoom"];
      area.zoom(k, x, y);
      area.update();
      for (let node of editor.nodes) {
        editor.view.updateConnections({ node });
      }
    }

    setTimeout(() => {
      editor.trigger("process");
    }, 1000);
    // setTimeout(() => {
    //   const node = Object.values(editor.nodes).find((n) => n.name == "start");
    //   editor.trigger("process", node);
    // }, 1000);

    this.editor = editor;
    this.engine = engine;
    bindEvent(this, "rearange", (node) => {
      editor.trigger("readonly", false);
      editor.trigger("arrange", { node: node });
      AreaPlugin.zoomAt(editor);
      editor.trigger("readonly", true);
      // сохраним позиции нод
      for (const node of editor.nodes) {
        this.$store.commit("routing/position", node);
      }
    });
    this.loaded = true;
    this.$watch("readonly", (to) => editor.trigger("readonly", to));

    // бинда на добляемые обхекты
    this.add_options
      .filter((d) => d.has_add)
      .map((d) => {
        bindEvent(
          this,
          `added:${d.type}`,
          (obj) => {
            this.$store.commit("routing/set_created", obj.service_id);
            this.$emit("reload");
          },
          eventBus
        );
      });
    eventBus.$emit("editor_state", !this.has_changed_data);
    this.$watch(
      () => this.has_changed_data,
      () => {
        eventBus.$emit("editor_state", !this.has_changed_data);
      }
    );

    this.editor.view.on("click", this.click_in_place);
    const cr_node = this.$store.getters["routing/created_node"]?.toLowerCase();
    if (cr_node) {
      this.readonly = false;
      this.show_node(cr_node);
    }

    // end of mounted
  },
  computed: {
    zoom_as_string: {
      get() {
        return parseInt(this.zoom.toFixed(2) * 100);
      },
      set(to) {
        let real_to = to / 100;
        const { area } = this.editor.view;
        //const { x, y } = this.$store.getters["routing/zoom"];
        area.zoom(real_to);
        area.update();
      },
    },

    add_options() {
      let ret = [
        {
          text: "Входящий звонок",
          icon: as_icon(faPhoneVolume),
          type: "city_number",
        },
        {
          text: "Внутренний номер",
          icon: as_icon(faUser),
          type: "internal_line2",
        },
        { text: "Группа", icon: as_icon(faUsers), type: "pbx_group" },
        {
          text: "Голосовое меню",
          icon: as_icon(faProjectDiagram),
          type: "ivr",
        },
        {
          text: "Голосовое сообщение",
          icon: as_icon(faPlayCircle),
          type: "play_file",
          has_add: true,
        },
        {
          text: "График работы",
          icon: as_icon(faCalendarAlt),
          type: "shch_check",
          has_add: true,
        },
        {
          text: "Донабор номера",
          icon: as_icon(faFax),
          type: "disa",
          has_add: true,
        },
        {
          text: "Завершить вызов",
          icon: as_icon(faPhoneSlash),
          type: "hangup",
          has_add: true,
        },
        {
          text: "Перевод на менеджера",
          // icon: as_icon(faInbox),
          icon: "manager",
          type: "integration",
          has_add: false,
          obit_icon: true,
        },
        {
          text: "Голосовая почта",
          icon: "setup_sound_box",
          type: "RoutedVoiceMail",
          has_add: false,
          obit_icon: true,
          unknown: true,
        },
        {
          text: "Управление голосовой почтой",
          icon: "sound_box",
          type: "VoiceMailAccess",
          has_add: false,
          obit_icon: true,
          unknown: true,
        },
        {
          text: "Уведомление на e-mail",
          icon: as_icon(faQuestion),
          type: "RingNotifyEmail",
          has_add: false,
          unknown: true,
          // obit_icon: true,
        },
        // {
        //   text: "Дополнительные сервисы",
        //   icon: as_icon(faWrench),
        //   items: [],
        // },
      ];
      var registred_types = [
        ...ret.map((o) => o.type).filter((o) => o != "unknown"),
      ];
      for (const o of this.additional_data.unknown || []) {
        let t = o.service_type;

        if (
          t != "unknown" &&
          registred_types.indexOf(t) === -1 &&
          IGNORE_UNKNOWN.indexOf(t) === -1
        ) {
          registred_types.push(t);
          ret.push({
            text: t,
            icon: as_icon(faQuestion),
            type: t,
            has_add: false,
            unknown: true,
          });
        } else {
          continue;
        }
      }
      return ret;
    },
    typ_descr() {
      if (this.type) {
        return this.$store.getters[`${this.type}/description`];
      }
      return null;
    },
    icons: () => ({
      up: as_icon(faChevronUp),
      down: as_icon(faChevronDown),
      bars: as_icon(faBars),
      reload: as_icon(faSync),
      save: as_icon(faSave),
      search: as_icon(faSearchLocation),
      trash: as_icon(faTrash),
      close: as_icon(faTimes),
      zoom_in: as_icon(faSearchPlus),
      zoom_out: as_icon(faSearchMinus),

      undo: as_icon(faUndo),
      edit: as_icon(faPenFancy),
    }),
    avalable_options() {
      return Object.entries(this.available_nodes).reduce(
        (r, [name, options]) => merge(r, { [name]: options.length }),
        {}
      );
    },
    avalable_options_current() {
      let ret =
        this.show_options != null
          ? this.available_nodes[this.show_options] || []
          : [];
      if (this.show_options_search.length) {
        let regex = new RegExp(this.show_options_search, "i");
        return ret.filter((o) => o.obj_name.search(regex) != -1);
      }
      return ret;
    },
    searh_placeholder() {
      let t = "Искать во всех";
      if (this.search_types.filter((o) => o.type != "__search__").length) {
        t = "Искать в выбранных";
      }
      return t;
    },
    available_nodes() {
      let ret = {};
      if (this.editor) {
        const nodes = this.editor.nodes;
        ret = nodes.reduce((r, node) => {
          if (node.name !== "start") {
            if (node.name == "unknown") {
              let count = r[node.data.raw_data.service_type] || [];
              if (!node.vueContext.real_show) {
                count.push(node.vueContext.service);
              }
              r[node.data.raw_data.service_type] = count;
            } else {
              let count = r[node.name] || [];
              if (!node.vueContext.real_show) {
                count.push(node.vueContext.service);
              }
              r[node.name] = count;
            }
          }
          return r;
        }, {});
      }
      return ret;
    },
  },
  methods: {
    toggle_hover(value) {
      if (value) {
        let ref = this.$refs.items;
        let buttons = this.$refs.items_buttons;
        let ret_size = ref.clientHeight;
        let button_size = buttons[0].clientHeight;
        let buttons_ = Math.ceil(
          buttons.length / Math.ceil(ret_size / button_size)
        );
        this.tools_size = `${buttons_ * 4}em`;
      } else {
        if (!this.show_options) {
          this.tools_size = "4em";
        }
      }
    },
    async do_reload() {
      let val = await this.$bvModal.msgBoxConfirm(
        "Вы хотите отменить изменения и завершить редактирование?",
        {
          title: "Отмена",
          okTitle: "Да",
          okVariant: "obit",
          cancelTitle: "Нет",
        }
      );
      if (val) {
        this.reloading = true;
        setTimeout(async () => {
          await this.engine.abort();
          // hide shown nodes
          for (const node of this.editor.nodes) {
            if (node.vueContext.added) {
              node.vueContext.node.data.show = false;
              node.data.show = false;
              node.vueContext.added = false;
            }
          }
          await this.editor.fromJSON(this.original_data);
          //await this.engine.process(this.original_data);
          this.reloading = false;
          this.has_changed_data = false;
        }, 250);
      }
    },
    do_restart() {
      this.$bvModal
        .msgBoxConfirm(
          "Вы действительно хотите перезагрузить редактор и вывести текущую схему маршрутизации входящих звонков?",
          {
            title: "Перезагрузка",
            okTitle: "Да",
            okVariant: "obit",
            cancelTitle: "Нет",
          }
        )
        .then((val) => {
          if (val) {
            this.$store.commit("routing/reset");
            this.$emit("reload");
          }
        });
    },
    get_add_node_name(obj) {
      let ret = obj.obj_name;
      if (obj.obj_type == "pbx_group") {
        ret = `${obj.obj_name} - ${obj.caption}`;
      }
      return ret;
    },
    click_in_place() {
      let { x, y } = this.editor.view.area.mouse;
      eventBus.$emit("click_with_coor", { x, y });
      this.block_everything = false;
    },
    search_tag(tag) {
      this.search_string = tag;
      const type = "__search__";
      let searchIndex = findIndex(this.search_types, (v) => v.type == type);
      if (searchIndex != -1) {
        this.search_types.splice(searchIndex, 1);
      }
      this.search_types.push({ text: tag, type });
      this.search_items();
    },
    search_items() {
      if (!this.search_enabled) return;
      let nodes = this.editor.nodes.filter((node) => node.data.show);
      nodes.map((node) => {
        eventBus.$emit(`search_node_${node.id}`, false);
      });
      if (this.search_types.length == 0 && this.search_string.length == 0)
        return;
      // сообщим нодам о сбросе

      let search_types = this.search_types
        .filter((o) => o.type !== "__search__")
        .map((o) => o.type);

      if (search_types.length) {
        nodes = nodes.filter((node) => {
          let ret = false;
          let nodeConfig = node.data.config;
          let nodeType = nodeConfig?.objType;
          if (search_types.indexOf(nodeType) != -1) {
            ret = true;
          }
          return ret;
        });
      }

      if (this.search_string.length) {
        let reg = new RegExp(`${this.search_string}`, "i");
        const defSearch = (node, regex) => {
          let nodeText = node.vueContext?.service?.obj_name;
          let ret = nodeText && nodeText.search(regex) != -1;
          return ret;
        };
        const _searcInNode = (node) => {
          const nodeConfig = node.data.config;
          const search = nodeConfig.textSearch || defSearch;
          return search(node, reg);
        };
        nodes = nodes.filter(_searcInNode);
      }
      if (nodes.length) {
        nodes.map((node) => {
          eventBus.$emit(`search_node_${node.id}`, true);
        });
        if (nodes.length == 1) {
          //zoom
          AreaPlugin.zoomAt(this.editor, nodes);
        }
      } else {
        this.$store.commit("add_message", {
          type: "danger",
          message: "Ничего не найдено",
        });
      }
    },
    del_from_seach_type(opt) {
      if (opt.type == "__search__") {
        this.search_string = "";

        //console.log("search", this.search_types);
      }
      this.search_items();
    },
    do_zoom(val) {
      let v = parseInt(val);
      let z = parseInt(this.zoom_as_string);

      if (z + v < 1) {
        this.zoom_as_string = 1;
      } else {
        this.zoom_as_string = z + v;
      }
    },
    toggle_options(type) {
      // if (this.has_changed_data) {
      //   this.type_name = this.add_options.find((s) => s.type === type).text;
      //   this.show_save = true;
      // } else {
      if (type) {
        let opts = this.avalable_options[type];
        let obj_type = this.add_options.find((o) => o.type == type);
        if (!opts && !obj_type.has_add) return;
      }
      this.show_options = this.show_options == type ? null : type;
      if (this.show_options) {
        this.show_options_obj = this.add_options.find((o) => o.type == type);
      } else {
        this.show_options_obj = null;
        this.show_options_search = "";
      }
      this.$forceUpdate();
      // }
    },
    toggle_options_outside() {
      this.toggle_options(this.show_options);
    },
    process() {
      this.editor.trigger("process");
    },
    save_data(use_confirm = false) {
      const _save = () => {
        let ret = pack_data(this.editor.toJSON(), this.data);
        this.$emit("save", ret);
        this.has_changed_data = false;
        this.show_add_modal();
      };
      if (use_confirm) {
        this.$bvModal
          .msgBoxConfirm(
            "Правила приема звонков будут изменены. Вы уверены, что хотите сохранить изменения?",
            {
              title: "Сохранить?",
              okTitle: "Да",
              cancelTitle: "Нет",
            }
          )
          .then((val) => {
            if (val) {
              _save();
            }
          });
      } else {
        _save();
      }
    },
    toggle_editor() {
      if (!this.readonly && this.has_changed_data) {
        this.$store.commit("add_message", {
          type: "danger",
          message: "Есть несохраненные данные",
        });
        return;
      }
      this.readonly = !this.readonly;
    },
    show_add_modal(item) {
      if (item !== undefined) {
        this.show_item = item;
      }
      const item_to_show = this.show_item;
      if (this.has_changed_data) {
        this.type_name = this.add_options.find(
          (s) => s.type === item_to_show.type
        ).text;
        this.show_save = true;
      } else {
        const { type, text } = item_to_show;
        this.add_title = text;
        this.type = type;
        this.new_obj = {};
        if (type) {
          if (item_to_show.has_add) {
            this.show_add = true;
            this.toggle_options(this.show_options);
          }
        }
      }
    },
    show_node(id) {
      // if (this.has_changed_data) {
      //   this.show_save = true;
      // } else {
      const obj_id = id.toLowerCase();
      this.block_everything = true;
      eventBus.$emit("show_node_" + obj_id);
      this.show_add = false;
      this.sidebar = false;
      this.toggle_options(this.show_options);
      // }
    },
    close_modal() {
      if (this.type) {
        this.$refs["add_modal"].update();
      }
    },
    // onCreateRequest(resp) {
    //   resp.then((obj) => {
    //     this.$store.commit("routing/set_created", obj.service_id);
    //     this.$emit("reload");
    //   });
    // },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
$breakpoint: 760px;

.active-node {
  z-index: 99999;
}

.routing-page {
  $zindex: 10000;
  .no-options {
    min-height: 3em;
  }
  .foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(lighten(gray, 20%), 0.3);
    backdrop-filter: blur(5px);
    z-index: $zindex + 1;
    .spinner {
      width: 10rem;
      height: 10rem;
      position: absolute;
      top: calc(50% - 5rem);
      left: calc(50% - 5rem);
      .sp {
        width: 100%;
        height: 100%;
      }
    }
    // .logo {
    //   background-image: url("@assets/obit_logo.svg");
    //   width: 10rem;
    //   height: 10rem;
    // }
  }
  .help {
    display: none;
  }
  .zoom-panel {
    position: absolute;
    bottom: 1em;
    left: 1em;
    z-index: $zindex;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    .custom_zoom {
      max-width: 4em;
    }
  }
  .editor-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn-group.controls {
      display: flex;
      flex-flow: row nowrap;
      justify-content: start;
      align-self: flex-start;
      //width: 100%;

      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(5px);
      border-radius: 5px;
      z-index: $zindex;
      & > * {
        z-index: $zindex;
      }
      button.btn {
        flex: inherit;
      }
      .multiselect__input {
        display: inline;
        //max-width: 15em;
      }
    }

    .custom_zoom,
    .search-bar {
      max-width: 15em;
      display: inline-block;
      padding-top: 4px;
      border-radius: 0;
    }
    .search-bar {
      width: 15em;
    }

    height: fit-content;
    position: absolute;
    width: calc(100% - 2em);
    left: 1em;
    top: 1em;
    @media screen and (max-height: $breakpoint) {
      &.openned {
        left: 6em;
        width: calc(100% - 7em);
      }
    }
    z-index: $zindex;
  }
  .tag-item {
    $margin-size: 0.25em;
    .close-icon {
      display: none;
    }
    &.text {
      background-color: transparent;
      color: black;
    }
    &:hover {
      background-color: $obit-danger;
      color: white;
      .text-danger {
        color: white !important;
      }
      .close-icon {
        display: inline-block;
        margin-left: 0.5em;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    margin-left: $margin-size;
    margin-right: $margin-size;

    padding-right: $margin-size;
    padding-left: $margin-size;
    font-size: 1em;
  }
  .sidebar {
    background-color: #f8f9fa;
    border: 1px solid gray;
    top: 0px;
    margin-left: calc(100% - 300px);
    width: 300px;
    position: absolute;
    border: 1px solid var(--light);
    .list-group.list-group-flush {
      max-height: calc(100vh - 310px);
      overflow: auto;
    }
  }
  .items-paletre {
    position: absolute;
    left: 1em;
    top: 6em;
    @media screen and (max-height: $breakpoint) {
      top: 1em;
    }
    z-index: 10000;
    display: flex;
    flex-direction: row;

    .btn-group {
      backdrop-filter: blur(3px);
      box-shadow: 0px 28px 64px -15px #54bae7;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-self: baseline;
      align-items: stretch;
      border-radius: 5px;
      overflow: auto;
      max-height: calc(100vh - 145px);
      background: rgba(255, 255, 255, 0.7);
      z-index: 10000;
      &:first-child {
        max-height: calc(100vh - 290px);
        flex-wrap: wrap;
        @media screen and (max-height: $breakpoint) {
          width: var(--hover-size);
          overflow: hidden;
        }
        button {
          flex-basis: inherit 4em;
        }
      }
      &:not(:first-child) {
        margin-left: 1em;
        box-shadow: 0px 28px 64px -35px #54bae7;
      }
      &.options {
        button.btn {
          text-align: left;
        }
      }
      button {
        max-width: 30em;
        .badge {
          // height: 0.5em;
          position: absolute;
          top: 10%;
          right: 10%;
          font-size: 0.5em;
        }
        .badge.unknown {
          top: inherit;
          bottom: 10%;
        }
      }
    }
  }
  .palitre {
    .sub-items {
      max-height: 30em;
      overflow: auto;
      min-height: 2em;
    }
  }
  &.add_item {
    .editor-controls,
    .items-paletre,
    .sidebar {
      display: none;
    }
    #rete {
      background-color: lighten($obit-success, 40%);
      cursor: copy;
    }
    .help {
      display: block;
      max-width: 50vw;
      position: absolute;
      top: 1.5em;
      left: 2em;
      z-index: 10000;
      backdrop-filter: blur(2px);
      padding: 0.5em;
      background-color: lighten(rgba($obit-success, 0.4), 20%);
      border-radius: 5px;
      // box-shadow: 0px 15px 10px -10px lighten($obit-success, 20%);
    }
  }
  #rete {
    // min-height: 600px;
    height: calc(100% - 40px);
    .card {
      min-width: 320px;
      width: 100%;
    }
    .connection .main-path {
      fill: none;
      stroke-width: 5px;
      stroke: var(--primary);
    }
    .connection.input-start.output-start {
      display: none;
    }
    .socket {
      border: 3px solid white;
      border-radius: 50%;
      background-color: var(--orange);
      box-shadow: 0 0 5px var(--primary);
      &:hover {
        box-shadow: 0 0 10px var(--primary);
      }
      &.input-socket {
        width: 0;
        height: 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: unset;
        left: 0;
        border-top: 12px solid transparent;
        border-left: 24px solid var(--orange);
        border-bottom: 12px solid transparent;
      }
      &.output-socket.output.start,
      &.input-socket.input.start {
        display: none;
      }
    }
  }
}
</style>